<template>
  <MainContainer
    data-test="cryobeacon-details"
    gridSlots="8
  "
  >
    <TopHeader>
      <HeaderSummary :sourceLocation="sourceLocation" :destinationLocation="destinationLocation" />
    </TopHeader>

    <ActionBar data-test="patient-view__action-bar" colsDistribution="8/4">
      <template v-slot:left-side>
        <DynamicTitle titleType="h2" floatTo="left"> Viewing CryoBeacon Details </DynamicTitle>
      </template>

      <template v-slot:right-side>
        <ButtonDynamic
          btnStyle="secondary"
          btnText="Back"
          btnType="button"
          showIcon
          fontAwesomeIconClass="arrow-left"
          @click="handleBack"
        />
      </template>
    </ActionBar>

    <BreadCrumbs :items="breadCrumsItems" size="base" />

    <div v-if="getSpecimens" class="cryobeacon-detail__wrapper bg-white rounded-lg p-6 mb-5">
      <div class="patient-info__box mt-4 flex flex-row items-end">
        <h3 class="patient-info__name font-bold text-4xl mr-2" data-test="patient-info-full-name">
          {{ getCryobeaconSelected.patientName }}
        </h3>

        <p
          class="patient-info__id ml-4 text-2xl font-inconsolata leading-7"
          data-test="patient-info-id"
        >
          Patient ID {{ getCryobeaconSelected.patient.id }}
        </p>
      </div>

      <CryoBeaconDetail
        :canSelect="false"
        :beaconId="getCryobeaconSelected.cryobeaconBarcode"
        :infectiousScreeningStatus="getCryobeaconSelected.screeningStatus"
        :currentLocation="getCryobeaconLocationRobot.cryoRobotName"
        :locationDetail="getCryobeaconLocationRobot.location"
        :specimens="getSpecimens"
        :dateOfBirth="dayjs(getCryobeaconSelected.patientDob).format('DDMMMYYYY').toUpperCase()"
      />
    </div>
  </MainContainer>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import HeaderSummary from '@/components/Biorepository/HeaderSummary/HeaderSummary.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import CryoBeaconDetail from '@/components/Biorepository/CryoBeaconDetail/CryoBeaconDetail.vue'
import useGetters from '@/composables/useGetters'
import useActions from '@/composables/useActions'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { getCryoBeaconLocation } from '@/helpers/cryoBeaconLocation'
import { getBiorepoBreadcrumbs } from '@/helpers/biorepository'
import { TICKET_RELOCATION } from '@/constants'

const { sourceLocation, destinationLocation } = useGetters('biorepoTicketModule')
const { container } = useGetters('patientsModule')
const { beaconsSpecimens } = useGetters('beaconsModule')
const { getCryobeaconSelected } = useGetters('massCreateModule')

const { getBeaconIdFromBarcode } = useActions('patientsModule')
const { fetchBeaconsSpecimens } = useActions('beaconsModule')

const locationDetails = ref<{ location: string; cryoRobotName: string }>({
  location: '',
  cryoRobotName: ''
})

const router = useRouter()
const route = useRoute()

onMounted(() => {
  getCryoBeaconDetails()
})

const breadCrumsItems = computed(() => {
  return getBiorepoBreadcrumbs({ ticketMode: TICKET_RELOCATION })
})

const handleBack = () => {
  router.back()
}

const getCryoBeaconDetails = async () => {
  try {
    const { cryobeaconBarcode } = route.params
    await getBeaconIdFromBarcode({
      beaconBarcode: cryobeaconBarcode,
      unitId: sourceLocation.value.id
    })
    await fetchBeaconsSpecimens({
      beacons: [{ barcode: cryobeaconBarcode, containerId: container.value.containerId }]
    })
    locationDetails.value = await getCryoBeaconLocation(container.value)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

const getSpecimens = computed(() => {
  return beaconsSpecimens.value[0]?.specimens || []
})

const getCryobeaconLocationRobot = computed(() => {
  if (!container.value) return { location: '', cryoRobotName: '' }
  const { location, cryoRobotName } = locationDetails.value
  return { location, cryoRobotName }
})
</script>
