import axios from 'axios'
import * as ss from '@/config/session-storage-help'
import { BASE_URL, BULK_URL, SINGLE_VIAL_READER_URL } from '@/config/http-constants'
import { axiosInterceptors } from '@/config/http-interceptors'

interface CreateRequest {
  baseUrl: string
  headers?: { [key: string]: string }
  hasClinicSiteParams?: boolean
}

const getSiteClinicParams = (): string => {
  const siteId = ss.getFieldSessionStorage('config', 'siteId') || null
  const clinicId = ss.getFieldSessionStorage('config', 'clinicId') || null
  return `/clinics/${clinicId}/sites/${siteId}`
}

export const createRequest = ({ baseUrl, headers, hasClinicSiteParams = false }: CreateRequest) => {
  const clinicSiteParams: string = getSiteClinicParams()
  const baseURL: string = `${baseUrl}${hasClinicSiteParams ? clinicSiteParams : ''}`

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      'x-access-token': ss.getFieldSessionStorage('user', 'token'),
      'content-type': 'application/json; charset=UTF-8',
      ...headers
    }
  })
  return axiosInterceptors(axiosInstance)
}

export const httpRequest = (
  hasClinicSiteParams: boolean = true,
  headers?: { [key: string]: string }
) => createRequest({ baseUrl: BASE_URL, hasClinicSiteParams, headers })

export const httpRequestBulk = (hasClinicSiteParams: boolean = true) =>
  createRequest({ baseUrl: BULK_URL, hasClinicSiteParams })

export const httpRequestSingleVialReader = () => axios.create({ baseURL: SINGLE_VIAL_READER_URL })
