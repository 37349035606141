import { ref, onMounted, Ref, watch } from 'vue'
import { useStore } from 'vuex'
import {
  Destination,
  DestinationOption,
  VaultShelfOption,
  VaultShelfValues,
  shelfOptions,
  shelfValues
} from '@/types'
import * as ss from '@/config/session-storage-help'
import { getDestination, getShelfLabel, isVaultByUnitId } from '@/helpers/cryoBeaconLocation'
import { OPTIONAL } from '@/constants'
import useGetters from './useGetters'

export function useGetDestination() {
  const store = useStore()
  const { ticketDetail } = useGetters('selectedTicketsModule')

  const destinationOptions: Ref<DestinationOption[]> = ref([])
  const selectedDestination: Ref<string> = ref('')
  const vaultShelfOptions: Ref<VaultShelfOption[]> = ref(shelfOptions)
  const selectedShelf: Ref<VaultShelfValues> = ref(OPTIONAL)

  const getDestinationOptions = async ({ showDefault }): Promise<void> => {
    try {
      store.dispatch('spinnerModule/displaySpinner', 'Loading destinations')
      const result: Destination[] = ss.getFieldSessionStorage('containersBySite')
      store.dispatch('spinnerModule/hideSpinner')
      const mappedDestinations = result.map((destination) => ({
        value: String(destination.containerId),
        label:
          `${destination.robotName}${
            destination.defaultRobot && showDefault ? ' (Default)' : ''
          }` || '',
        robotLayoutTypeId: destination.robotLayoutTypeId,
        defaultRobot: destination.defaultRobot || false
      }))
      destinationOptions.value = mappedDestinations
      store.dispatch('ticketsModule/fetchDestinationOptions')
      setDefaultDestination()
    } catch (error) {
      console.error('Error getting destinations:', error)
    }
  }

  const setDefaultDestination = (): void => {
    const defaultDestination = destinationOptions.value.find(
      (destination) => destination.defaultRobot
    )
    if (defaultDestination) {
      onSelectDestination(defaultDestination.value)
    }
  }

  const onSelectDestination = (value: string | number): void => {
    if (!isVaultByUnitId(Number(value))) {
      selectedShelf.value = OPTIONAL
      store.dispatch('ticketsModule/setSelectedShelf', OPTIONAL)
    }
    if (selectedDestination.value === value) return
    selectedDestination.value = String(value)
    setDefaultDestinationShelf()
    store.dispatch('ticketsModule/setSelectedDestination', value)
  }

  const onSelectShelf = (value: VaultShelfValues) => {
    if (!shelfValues.includes(value)) {
      selectedShelf.value = OPTIONAL
      store.dispatch('ticketsModule/setSelectedShelf', OPTIONAL)
      return
    }
    selectedShelf.value = value
    store.dispatch('ticketsModule/setSelectedShelf', value)
  }

  const resetDestination = () => {
    selectedDestination.value = ''
    selectedShelf.value = OPTIONAL
    store.dispatch('ticketsModule/setSelectedDestination', '')
    store.dispatch('ticketsModule/setSelectedShelf', OPTIONAL)
  }

  const setDefaultShelf = (shelfId: string) => {
    const shelfSelected = getShelfLabel(shelfId)?.toUpperCase()
    if (shelfSelected) {
      onSelectShelf(shelfSelected as VaultShelfValues)
    }
  }

  const setDefaultDestinationShelf = () => {
    const destination =
      ticketDetail.value?.robotLocations && getDestination(ticketDetail.value.robotLocations)
    if (destination) {
      setDefaultShelf(destination.shelf)
    }
  }

  onMounted(() => {
    getDestinationOptions({ showDefault: true })
  })

  watch(ticketDetail, () => {
    try {
      setDefaultDestinationShelf()
    } catch {
      //
    }
  })

  return {
    getDestinationOptions,
    destinationOptions,
    vaultShelfOptions,
    selectedShelf,
    onSelectShelf,
    onSelectDestination,
    selectedDestination,
    resetDestination,
    setDefaultDestination,
    setDefaultDestinationShelf
  }
}
