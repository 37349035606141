import * as sessionStorage from '@/config/session-storage-help'
import { TICKET_OPEN_CAPITALS } from '@/constants'

export default {
  tickets: [],
  moveLocationTickets: [],
  filter: TICKET_OPEN_CAPITALS,
  filterBiorepo: TICKET_OPEN_CAPITALS,
  error: null,
  status: 'completed',
  destinationOptions: sessionStorage.getFieldSessionStorage('destinationOptions') || [],
  selectedDestination: sessionStorage.getFieldSessionStorage('selectedDestination') || '',
  selectedShelf: sessionStorage.getFieldSessionStorage('selectedShelf') || null
}
