import { ref } from 'vue'
import { validateDestination } from '@/services/tickets'
import toast from '@/config/toast'
import { CRYOROBOT, TEXT_BEACON, SCREENING_STATUSES } from '@/constants'

export function useValidateDestination() {
  const unitSpaceValidationFailed = ref(false)
  const validateUnitSpace = async ({ containerId, screeningStatus }): Promise<boolean> => {
    try {
      await validateDestination({
        containerId,
        screeningStatus
      })
      unitSpaceValidationFailed.value = false
    } catch (error) {
      unitSpaceValidationFailed.value = true
      toast.error({
        title: `The selected ${CRYOROBOT} is full`,
        message: `We’re sorry but there is no space for ${SCREENING_STATUSES[screeningStatus]} ${TEXT_BEACON}/s in the selected ${CRYOROBOT}. Please remove some Tickets and try again.`
      })
      return false
    }
    return true
  }

  const setUnitSpaceValidationFailed = (value) => {
    unitSpaceValidationFailed.value = value
  }

  return {
    validateUnitSpace,
    setUnitSpaceValidationFailed,
    unitSpaceValidationFailed
  }
}
