import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import toast from '@/config/toast'
import * as ss from '@/config/session-storage-help'
import {
  getTicketsByIdApi,
  getTicketListApi,
  getDestinationOptions,
  updateTicketCryoBeaconApi
} from '@/services/tickets'

// TODO CRYOSHIPPER: Remove mock
// import { mockCryoShipperTicket } from '@/tests/mocks/tickets/cryoshipperMock'

import {
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  PROCEDURE_TYPE_BEACON_MOVE,
  PROCEDURE_TYPE_CRYOSHIPPER,
  TICKET_OPEN_CAPITALS
} from '@/constants'
import { dateRangeHelper } from '@/helpers/flightBoardHelpers'

import { CRYOSHIPPER_ENABLED_FF, isFeatureEnabled } from '@/helpers/featureFlags'

dayjs.extend(utc)

export default {
  // TODO: This action should be refactored to cover different scenarios.

  async fetchTickets({ commit, rootState }, ticketState) {
    const {
      appBaseConfig: { collectionProtocolId, clinicId, siteId }
    } = rootState.authModule
    const { dateFromMsUtc, dateToMsUtc } = dateRangeHelper(false, ticketState)

    try {
      // Show loading spinner.
      commit('loading')

      const ticketsParams = {
        dateFromMsUtc,
        dateToMsUtc,
        cpId: collectionProtocolId,
        excludeCompletedBatches: true,
        ticketState // Constant is Camel Case, therefor using string.
      }
      const ticketResponse = await getTicketListApi({
        clinicId,
        siteId,
        collectionProtocolId,
        params: ticketsParams
      })

      const combinedTickets = ticketResponse.data

      // Commit action and remove spinner.
      commit('fetchTickets', combinedTickets)

      commit('success')
    } catch (err) {
      commit('error', err)
      toast.error({ title: err.message })
      throw new Error(err.message)
    }
  },

  async fetchTicketsById({ rootState }, ids) {
    const {
      appBaseConfig: { collectionProtocolId }
    } = rootState.authModule
    const ticketIds = ids.join()

    try {
      const response = await getTicketsByIdApi({
        collectionProtocolId,
        ids: ticketIds
      })
      return response.data
    } catch (error) {
      const { response } = error
      const [{ message }] = response.data
      if (message.indexOf('VISIT_NOT_FOUND') !== -1) {
        const error404 = new Error('404 Visit not found')
        throw Error(error404)
      }
      throw new Error(error.message)
    }
  },

  async fetchMoveLocationTickets({ commit, rootState }, options) {
    const {
      appBaseConfig: { collectionProtocolId, siteId: userSiteId, clinicId }
    } = rootState.authModule
    const {
      ticketState,
      sourceSiteId,
      destinationSiteId,
      includeAllSites = true,
      onlyRelocation = false,
      includeDateRange = true,
      siteMode,
      robotTransfer
    } = options
    const siteId = sourceSiteId || userSiteId
    const dateRanges = includeDateRange ? dateRangeHelper(true, ticketState) : {}
    const procedures = [PROCEDURE_TYPE_BEACON_MOVE]
    const checkSiteIdInMetadata = isFeatureEnabled(CRYOSHIPPER_ENABLED_FF)
    if (checkSiteIdInMetadata) {
      procedures.push(PROCEDURE_TYPE_CRYOSHIPPER)
    }
    const params = {
      // TODO: feature-flag and constants
      procedure: procedures.join(','),
      includeAllSites,
      skipExclusions: true,
      excludeCompletedBatches: true,
      ...dateRanges,
      destinationSiteId,
      checkSiteIdInMetadata,
      ticketState, // Constant is Camel Case, therefor using string.
      ...(robotTransfer !== undefined ? { robotTransfer } : {}),
      ...(siteMode !== undefined ? { siteMode } : {}),
      includeInitial: true
    }

    try {
      const moveTicketsResponse = await getTicketListApi({
        collectionProtocolId,
        clinicId,
        siteId,
        params
      })
      // TODO CRYOSHIPPER: Remove mock
      // if (!destinationSiteId || !sourceSiteId) {
      //   moveTicketsResponse.data.push(mockCryoShipperTicket)
      // }
      let moveTickets = moveTicketsResponse.data
      if (onlyRelocation) {
        moveTickets = moveTickets.filter(
          ({ procedure }) =>
            ![PROCEDURE_TYPE_BATCH_UPPERCASE, PROCEDURE_TYPE_CRYOSHIPPER].includes(procedure)
        )
      }
      commit('fetchMoveLocationTickets', moveTickets)
      commit('success')
    } catch (err) {
      commit('error', err)
      toast.error({ title: err.message })
      throw new Error(err.message)
    }
  },

  async fetchDestinationOptions({ commit }) {
    try {
      const response = await getDestinationOptions({ bySite: true })
      ss.setFieldSessionStorage('destinationOptions', response.data)
      commit('setDestinationOptions', response.data)
      return response.data
    } catch (error) {
      toast.error({ title: error.message })
      throw error
    }
  },

  setSelectedDestination({ commit }, destination) {
    ss.setFieldSessionStorage('selectedDestination', destination)
    commit('setSelectedDestination', destination)
  },

  setSelectedShelf({ commit }, shelf) {
    ss.setFieldSessionStorage('selectedShelf', shelf)
    commit('setSelectedShelf', shelf)
  },

  setFilter({ commit }, filterValue) {
    commit('setFilter', filterValue)
  },

  setFilterBiorepo({ commit }, filterValue) {
    commit('setFilterBiorepo', filterValue)
  },

  async updateTicketCryoBeacon(_, updateData) {
    const { ticketId, beaconId } = updateData
    await updateTicketCryoBeaconApi({ ticketId, beaconBarcode: beaconId })
  },

  resetStateFilterTabs({ commit }) {
    commit('setFilter', TICKET_OPEN_CAPITALS) // Default filter
    commit('setFilterBiorepo', TICKET_OPEN_CAPITALS) // Default filter
  }
}
