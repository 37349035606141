import { TOP, MIDDLE, BOTTOM, OPTIONAL } from '@/constants'

export interface VaultShelfOption {
  value: string
  label: string
  default?: boolean
}

export enum VaultShelfLabels {
  top = 'Top Shelf',
  middle = 'Middle Shelf',
  bottom = 'Bottom Shelf'
}

export type VaultShelfValues = typeof TOP | typeof MIDDLE | typeof BOTTOM | typeof OPTIONAL

export const shelfValues: VaultShelfValues[] = [TOP, MIDDLE, BOTTOM]

export const shelfOptions: VaultShelfOption[] = [
  { value: OPTIONAL, label: '(Optional)', default: true },
  { value: shelfValues[0], label: VaultShelfLabels.top },
  { value: shelfValues[1], label: VaultShelfLabels.middle },
  { value: shelfValues[2], label: VaultShelfLabels.bottom }
]
