import { METHOD_NOT_SELECTED } from '@/constants'
import { STEP_INITIAL } from '@/constants/ticketSteps'

/**
 * Initial State of clinic Local Storage
 */

const initialBase = {
  config: {
    clinicId: '',
    siteId: '',
    unitId: '',
    clinicName: '',
    siteName: '',
    collectionProtocolId: '',
    emrIntegration: false
  },
  errorLog: [],
  user: {
    firstName: '',
    lastName: '',
    token: '',
    tokenExpiresAt: '',
    userId: '',
    userName: '',
    userType: ''
  },
  process: {
    selectedTickets: [],
    selectedTicketToEdit: {},
    patientFullName: ''
  },
  screeningStatus: {
    data: []
  },
  newTicket: {
    beacons: [],
    cryoDevice: [],
    currentStep: 1,
    method: '',
    type: '',
    typeEmbryo: '',
    patient: {},
    procedureDateFrom: null,
    procedureDateTo: null,
    procedureTime: null,
    selectedMethod: METHOD_NOT_SELECTED,
    selectedCryoDeviceType: null,
    currentTicketStep: STEP_INITIAL,
    selectedSpecimenType: null,
    selectedPatient: null,
    source: null,
    shippingSourceId: null,
    donorId: null,
    selectedTypeEmbryo: null
  },
  biorepositoryTicket: {
    allSites: [],
    sourceLocation: null,
    destinationLocation: null,
    selectedPatient: {
      fullName: null,
      globalPatientNumber: null
    },
    selectedBeacons: []
  }
}

/**
 * Private Functions setters and getters
 * Set initial sessionStorage state
 */
const setInitialState = () => {
  const init = JSON.stringify(initialBase)
  sessionStorage.setItem('clinic', init)
  const clinic = sessionStorage.getItem('clinic')
  return JSON.parse(clinic)
}

/**
 * Set new sessionStorage state
 * @param {*} state
 */
export const setNewSessionStorage = (state) => {
  sessionStorage.setItem('clinic', JSON.stringify(state))
  const clinic = sessionStorage.getItem('clinic')
  return JSON.parse(clinic)
}

/**
 * Get entire sessionStorage state
 */
export const getSessionStorage = () => {
  let clinic = sessionStorage.getItem('clinic')
  clinic = JSON.parse(clinic)
  return clinic
}

/**
 * Set field to local storage receive a field string
 * and new value to set node for set main node structure
 * @param {*} field
 * @param {*} value
 * @param {*} [node]
 */
export const setFieldSessionStorage = (field, value, node) => {
  let clinic = getSessionStorage()

  if (!clinic) {
    clinic = setInitialState()
  }

  if (node && field) {
    if (!clinic[node]) {
      clinic[node] = {}
    }
    clinic[node][field] = value
  } else if (node && !field) {
    clinic[node] = value
  } else {
    clinic[field] = value
  }

  return setNewSessionStorage(clinic)
}

/**
 * Remove field from clinic local storage receive a field string to remove
 * @param {*} node
 * @param {*} field
 */
export const removeFieldSessionStorage = (node, field = null) => {
  let clinic = getSessionStorage()
  if (!clinic) {
    clinic = setInitialState()
  }

  /** remove entire luois  */
  if (!node && !field) {
    sessionStorage.removeItem('clinic')
    return setInitialState()
  }
  if (node && field) {
    const clincNode = clinic[node]
    if (!clincNode) {
      return setNewSessionStorage(clinic)
    }

    clincNode[field] = initialBase[node][field]
  }
  if (node && !field) {
    clinic[node] = initialBase[node]
  }

  return setNewSessionStorage(clinic)
}

/**
 * Get field from sessionStorage
 * @param {*} node
 * @param {*} field
 */
export const getFieldSessionStorage = (node, field = undefined) => {
  let clinic = getSessionStorage()
  if (!clinic) {
    clinic = setInitialState()
  }

  if (node && !field) {
    return clinic[node]
  }

  if (clinic[node] && clinic[node][field]) {
    return clinic[node][field]
  }

  return initialBase[node]?.[field]
}

/**
 * Get Token Session Storage
 */
export const getSessionStorageToken = () => sessionStorage.getItem('token')

/**
 * Set Session Storage token
 */
export const setSessionStorageToken = (token) => sessionStorage.setItem('token', token)

/**
 * Set Token Cookie Storage
 */
export const setCookieToken = (token) => {
  document.cookie = `token=${token}`
}

/**
 * Get Token Cookie Storage
 */
export const getCookieToken = () => {
  const name = 'token='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const clear = () => {
  sessionStorage.clear()
}
