<template>
  <MainContainer data-test="biorepository-flight-board" gridSlots="4">
    <top-header>Create a New CryoShipper Ticket</top-header>
    <ActionBar data-test="customize-beacons__action-bar" colsDistribution="6/6" setLeftSideToBottom>
      <template v-slot:left-side>
        <message-card class="float-left">
          <span>Select Relocation Tickets to Add to CryoShipper Ticket</span>
        </message-card>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnType="button"
          btnText="Cancel"
          btnStyle="secondary"
          addMarginLeft
          @click="handleCancel"
        />
        <button-dynamic
          btn-data-test="new-batch-ticket-button"
          btnType="button"
          btnText="Add Selected Tickets"
          btnStyle="primary"
          addMarginLeft
          :is-disabled="addDisabled"
          iconPosition="right"
          show-icon
          font-awesome-icon-class="plus"
          @click="handleNewCryoShipperTicket"
        />
      </template>
    </ActionBar>
    <bread-crumbs size="base" :items="breadCrumsItems" />
    <!-- @vue-ignore -->
    <ScrollTableComponent
      :minHeight="hasTickets ? 'h-full' : 'h-56'"
      v-model="tableData"
      selectionMode="checkbox"
      :headers="tableAddRelocationTickets"
      :isRelocationView="true"
      :headerInfo="headerInfo"
      tableWrapperClasses="rounded-b-md overflow-hidden"
      :hasTabs="false"
      @onButtonClicked="onViewClicked"
    />
  </MainContainer>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import MessageCard from '@/components/MessageCard/MessageCard.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'

import { tableAddRelocationTickets } from '@/constants/table-headers/relocation-headers'

import {
  TICKET_SCHEDULED,
  TICKET_IN_PROGRESS,
  TICKET_COMPLETE,
  TICKET_OPEN_CAPITALS,
  TICKET_CRYOSHIPPER,
  ROBOT_TRANSFER_OUT,
  TICKET_INITIAL
} from '@/constants'

import {
  CRYOSHIPPER_CONFIRM,
  ROUTE_MOVE_TICKET,
  STEP_BIOREPOSITORY_FLIGHTBOARD
} from '@/constants/moveLocationTicketSteps'
import { getTotalNumTickets } from '@/helpers/ticketState'
import { computed, onMounted, ref, watch } from 'vue'
import useGetters from '@/composables/useGetters'
import useActions from '@/composables/useActions'
import { useRouter } from 'vue-router'

import { BiorepositoryTicketModule } from '@/store/modules/biorepositoryTicket'
import toast from '@/config/toast'
import { getBiorepoBreadcrumbs } from '@/helpers/biorepository'

import exclamationIcon from '@/assets/exclamation-triangle-dark.svg'

dayjs.extend(utc)

const MAX_TICKETS = 49

const router = useRouter()

const breadCrumsItems = computed(() => {
  return getBiorepoBreadcrumbs({ active: 'details', ticketMode: TICKET_CRYOSHIPPER })
})

const tableData = ref<any>({
  sort: {
    orderBy: 'procedureTime',
    direction: 'desc'
  },
  options: []
})

const { moveLocationTickets } = useGetters('ticketsModule')

const { sourceLocation, destinationLocation, cryoShipperTickets } =
  useGetters<BiorepositoryTicketModule['getters']>('biorepoTicketModule')

const hasTickets = computed(() => {
  return !!tableData.value.options.length
})

const ticketsByStatus = computed(() => {
  const tickets = {}
  cryoShipperTickets.value?.forEach((ticket) => {
    if (!tickets[ticket.screeningStatus]) {
      tickets[ticket.screeningStatus] = []
    }
    tickets[ticket.screeningStatus].push(ticket)
  })

  return tickets
})

const isValid = computed(() => {
  const keys = Object.keys(ticketsByStatus.value)
  // Check if there is more than 2 status
  if (keys.length === 3) {
    return false
  }
  // Check if there is only 1 status, allows double max tickets (98)
  if (keys.length === 1) {
    return ticketsByStatus.value[keys[0]].length <= MAX_TICKETS * 2
  }
  // If there is two status, allows 49 each
  for (let i = 0; i < keys.length; i += 1) {
    if (ticketsByStatus.value[keys[i]].length > MAX_TICKETS) {
      return false
    }
  }
  return true
})

const addDisabled = computed(() => {
  return !hasSelectedTickets.value || !isValid.value
})

const hasSelectedTickets = computed(() => {
  return !!cryoShipperTickets.value?.length
})

const headerInfo = computed(() => {
  const items = tableData.value.options.flat()
  const totalNumTickets = getTotalNumTickets(items)
  return `<span class="font-normal">Displaying </span>${totalNumTickets} eligible Relocation Tickets <span class="font-normal">from your</span> Relocation Flight Board. <span class="font-normal">Select any column header to reorder the display.</span>`
})

const { fetchMoveLocationTickets } = useActions('ticketsModule')
const { displaySpinner, hideSpinner } = useActions('spinnerModule')
const { setSelectedTickets, setSelectedViewMode } = useActions('selectedTicketsModule')
const { setCryoShipperTickets } =
  useActions<BiorepositoryTicketModule['actions']>('biorepoTicketModule')

const onViewClicked = (ticket) => {
  setSelectedViewMode(TICKET_CRYOSHIPPER)
  setSelectedTickets([ticket.value])
  router.push({ name: ROUTE_MOVE_TICKET })
}

const handleNewCryoShipperTicket = async () => {
  router.push({ name: CRYOSHIPPER_CONFIRM })
}

const groupTicketsByStatus = (tickets) => {
  const readyForMoveTickets = tickets.filter((ticket) => ticket.state === TICKET_SCHEDULED)
  const readyForOffSiteTickets = tickets.filter((ticket) => ticket.state === TICKET_IN_PROGRESS)
  const completeTickets = tickets.filter((ticket) => ticket.state === TICKET_COMPLETE)
  const initialTickets = tickets.filter((ticket) => ticket.state === TICKET_INITIAL)

  const ticketsGrouped = [
    readyForMoveTickets,
    readyForOffSiteTickets,
    completeTickets,
    initialTickets
  ]

  if (!ticketsGrouped.flat().length) return []

  return ticketsGrouped.flat()
}

const handleCancel = () => {
  router.push({ name: STEP_BIOREPOSITORY_FLIGHTBOARD })
}

onMounted(async () => {
  setSelectedTickets([])
  setSelectedViewMode('normal')
  try {
    displaySpinner()
    await fetchMoveLocationTickets({
      ticketState: TICKET_OPEN_CAPITALS,
      sourceSiteId: sourceLocation.value?.id,
      destinationSiteId: destinationLocation.value?.id,
      includeAllSites: false,
      includeDateRange: false,
      siteMode: undefined,
      onlyRelocation: true,
      robotTransfer: ROBOT_TRANSFER_OUT
    })
  } finally {
    hideSpinner()
  }
})

watch(moveLocationTickets, (nextTickets) => {
  // @ts-ignore
  const tickets = groupTicketsByStatus(nextTickets)
  const selectedIds = cryoShipperTickets.value.map(({ ticketId }) => ticketId)
  const options = tickets.map((ticket) => ({
    ...ticket,
    selected: selectedIds.includes(ticket.ticketId)
  }))
  tableData.value = {
    ...tableData.value,
    options
  }
})
watch(tableData, (nextTableData, prevTableData) => {
  if (nextTableData.options !== prevTableData.options) {
    // @ts-ignore
    const selectedTickets = nextTableData.options.flat().filter((option) => option.selected)
    setCryoShipperTickets(selectedTickets)
  }
})
watch(isValid, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    const doesErrorMessageExist = [...document.getElementsByClassName('!bg-tmrw-warning')]
    doesErrorMessageExist.map((toast) => toast.remove())
    if (!newValue) {
      toast.customError({
        title: `Only 1-${
          MAX_TICKETS * 2
        } CryoBeacons, with up to 2 screening statuses (one per CryoGrid) can be added at a time.`,
        icon: exclamationIcon,
        customClasses: ['!bg-tmrw-warning !text-tmrw-blue-dark']
      })
    }
  }
})
</script>
