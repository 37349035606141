/**
 * ticket-controller
 * http://localhost:9000/swagger-ui.html#/ticket-controller
 */
import {
  PROCEDURE_MISSING_CRYOBEACON,
  PROCEDURE_TYPE_CRYOSHIPPER,
  TICKET_OPEN_CAPITALS
} from '@/constants'
import { fetchPatientsById } from '@/services/patients'
import { httpRequest } from '@/helpers/apiHelpers'
import {
  CreateTicketResponse,
  CreateTickets,
  UpdateTicket,
  GetTicket,
  GetTicketsByPatientId,
  GetTicketsByIdApi,
  DeleteTicketApi,
  GetTicketListApi,
  GetTicketsByBeaconsApi,
  GetDestinationOptions,
  ValidateDestination,
  UpdateTicketListApi,
  Ticket,
  GetRobotLocationApi
} from './types'

import { getShipmentDetails } from '@/helpers/biorepository'

// TODO CRYOSHIPPER: Remove mock
// import { mockCryoShipperTicket } from '@/tests/mocks/tickets/cryoshipperMock'
import { fetchShipmentById } from '../shipments'

export const createTicketsApi = ({ clinicId, siteId, ticketsArray }: CreateTickets) =>
  httpRequest(false).post<CreateTicketResponse[]>(
    `/clinics/${clinicId}/sites/${siteId}/tickets`,
    ticketsArray
  )

export const updateTicketApi = ({ ticketsArray, ticketId }: UpdateTicket) =>
  httpRequest().patch(`/tickets/${ticketId}`, ticketsArray)

export const getTicketApi = async ({ ticketId }: GetTicket) => {
  try {
    const response = await httpRequest().get<Ticket[]>(`/tickets/${ticketId}`)
    return response
  } catch (error) {
    return error
  }
}

export const getTicketListApi = async ({
  collectionProtocolId,
  clinicId,
  siteId,
  params
}: GetTicketListApi) =>
  httpRequest(false).get(`/clinics/${clinicId}/sites/${siteId}/tickets`, {
    params: {
      ...params,
      cpId: collectionProtocolId
    }
  })

export const getTicketsByPatientId = ({
  clinicId,
  collectionProtocolId,
  includeSharedTickets = false,
  includeAllSites = false,
  patientId,
  includeChildTickets = false
}: GetTicketsByPatientId) =>
  httpRequest().get('/tickets', {
    params: {
      clinicId,
      cpId: collectionProtocolId,
      globalPatientNumber: patientId,
      ticketState: TICKET_OPEN_CAPITALS,
      skipExclusions: true,
      includeAllSites,
      includeSharedTickets,
      includeChildTickets
    }
  })

export const getMissingTicketsByPatientId = ({
  clinicId,
  collectionProtocolId,
  includeSharedTickets = false,
  patientId
}: GetTicketsByPatientId) =>
  httpRequest().get('/tickets', {
    params: {
      clinicId,
      cpId: collectionProtocolId,
      globalPatientNumber: patientId,
      includeSharedTickets,
      procedure: PROCEDURE_MISSING_CRYOBEACON,
      ticketState: TICKET_OPEN_CAPITALS
    }
  })

export const getTicketsByIdApi = ({ collectionProtocolId, ids }: GetTicketsByIdApi) =>
  httpRequest().get<Ticket[]>(`/tickets/${ids}`, {
    params: { cpId: collectionProtocolId }
  })

export const getTicketsByBeaconsApi = ({
  beaconBarcodes,
  clinicId,
  collectionProtocolId,
  ticketState = 'OPEN',
  skipExclusions = true,
  includeChildTickets = false
}: GetTicketsByBeaconsApi) =>
  httpRequest().get('/tickets', {
    params: {
      clinicId,
      cpId: collectionProtocolId,
      beaconBarcodes: beaconBarcodes.join(),
      ticketState,
      skipExclusions,
      includeChildTickets
    }
  })

export const deleteTicketApi = ({ ticketId, cancelReason, userId }: DeleteTicketApi) =>
  httpRequest().delete(`/tickets/${ticketId}`, {
    data: { cancelReason, userId }
  })

export const getDestinationOptions = ({ bySite = true }: GetDestinationOptions) =>
  httpRequest().get(`/containers?includeTemporary=true&includeNonTmrw=true&bySite=${bySite}`)

export const validateDestination = ({ containerId, screeningStatus }: ValidateDestination) =>
  httpRequest().get(`/containers/${containerId}/validations?screeningStatus=${screeningStatus}`)

export const updateTicketCryoBeaconApi = async ({ ticketId, beaconBarcode }: UpdateTicketListApi) =>
  httpRequest().patch(`/tickets/${ticketId}`, [
    {
      ticketId,
      beaconBarcode
    }
  ])

export const fetchTicketData = async (collectionProtocolId, ids) => {
  try {
    // TODO CRYOSHIPPER: Remove mock
    const response = await getTicketsByIdApi({ collectionProtocolId, ids })

    // TODO CRYOSHIPPER: Remove mock
    // let response
    // if (ids.includes('999999')) {
    //   response = {
    //     data: [mockCryoShipperTicket]
    //   }
    // } else {
    //   response = await getTicketsByIdApi({ collectionProtocolId, ids })
    // }
    const patientIds = response.data.map(({ globalPatientNumber }) => globalPatientNumber)
    const shipmentPromises = response.data
      .filter(({ procedure }) => procedure === PROCEDURE_TYPE_CRYOSHIPPER)
      .map(({ metadata: { shipmentId } }) => fetchShipmentById({ shipmentId }))
    const patients = await fetchPatientsById(patientIds)
    const shipments = await Promise.all(shipmentPromises)
    const ticketData = response.data.map((ticket, index) => ({
      ...ticket,
      patient: patients[index],
      ...getShipmentDetails({ ticket, shipments })
    }))
    return ticketData
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getRobotLocationApi = ({
  cassetteId,
  rowId,
  columnId,
  unitId
}: GetRobotLocationApi) => {
  return httpRequest(true).get(
    `/units/${unitId}/cryogrids/${cassetteId}/rows/${rowId}/columns/${columnId}`
  )
}
