<template>
  <MainContainer data-test="label-scan-view" gridSlots="3">
    <top-header @backStep="handleClickBack">
      <HeaderInfo />
    </top-header>
    <ActionBar data-test="label-scan__action-bar" colsDistribution="6/6">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left"
          >Please scan each Cryodevice label</dynamic-title
        >
        <TicketStatusTags :isEmr="isEMRTicket" />
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnDataTest="label-scan__next-button"
          btnType="button"
          btnText="Next"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="arrow-circle-right"
          :isDisabled="!cryodevicesIdChecked || !isSpaceAvailableInSelectedUnit"
          @click="onClickNext"
        />
      </template>
    </ActionBar>
    <section v-if="activeBeacon.cryoDevice" class="main-container main-container--breadcrumb">
      <div class="label-scan">
        <BreadCrumbs :items="getActiveBreadcrumbs" />
        <div
          class="w-full rounded-tl-md rounded-tr-md bg-white py-2 px-4 border-b-1 border-solid border-tmrw-blue-dark"
        >
          <div class="grid grid-cols-12">
            <div class="col-span-2 flex items-start justify-center flex-col">
              <p class="uppercase text-xs font-bold mb-2">STORE TO</p>
              <p class="text-tmrw-blue font-semibold pb-2">{{ getCurrentDestination }}</p>
            </div>
            <div v-if="showShelfLabel" class="col-span-2 flex items-start justify-center flex-col">
              <p class="uppercase text-xs font-bold mb-2">SHELF ASSIGNMENT</p>
              <p class="text-tmrw-blue font-semibold pb-2">{{ getCurrentShelf }}</p>
            </div>
            <div class="col-span-2 flex items-start justify-center flex-col">
              <p data-test="cryobeacon-count-title" class="uppercase text-xs font-bold -mt-1">
                {{ CRYOBEACON }} count
              </p>
              <CryobeaconNumber
                :activeBeaconCounter="computeActiveBeaconCounterMsg"
                :showBackground="false"
                titleType="h1-blue-light-medium-bold"
                customPadding="p-0"
              />
            </div>
            <div class="col-span-2 flex items-start justify-center flex-col">
              <p class="uppercase text-xs font-bold">Infectious Screening Status</p>
              <ScreeningStatusLabel
                :id="cryodeviceLabelsData[0]?.screeningStatus"
                class="pr-6 font-semibold text-tmrw-blue"
                text-color="yellow"
                icon-color="yellow"
                :is-full-width="false"
              />
            </div>
            <div class="col-span-3 flex items-start justify-center flex-col">
              <p
                class="uppercase text-xs font-bold mb-2"
                data-test="details__action-bar--cryodevice-type-title"
              >
                {{ CRYODEVICE }} Type
              </p>
              <p
                class="text-tmrw-blue font-semibold pb-2"
                data-test="details__action-bar--cryodevice-type"
              >
                {{ cryoDeviceTypeValue }}
              </p>
            </div>
          </div>
        </div>
        <EditableTable
          :items="activeBeacon.cryoDevice"
          :specimenType="selectedSpecimenType"
          :edit="false"
          :isReprintVisible="true"
          :modalEdit="modalEdit"
          @changeValue="handleSpecimenChange"
        />
      </div>
    </section>
    <loading-ui modal v-if="isLoaderVisible" :message="loaderMessage" />
  </MainContainer>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import EditableTable from '@/components/EditableTable/EditableTable.vue'
import ScreeningStatusLabel from '@/components/ScreeningStatusLabel/ScreeningStatusLabel.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import TicketStatusTags from '@/components/TicketStatusTags/TicketStatusTags.vue'
import CryobeaconNumber from '@/components/CryoBeaconNumber/CryoBeaconNumber.vue'
import {
  EXISTING_BEACON_STEP,
  ROBOT_TRANSFER_IN,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_NOTES,
  BIOPSY_RESULT,
  CRYOBEACON,
  CRYOROBOT,
  CRYODEVICE,
  OPTIONAL
} from '@/constants'
import { useTicket } from '@/composables'
import store from '@/store'
import {
  activeBeaconCounterMsg,
  getBreadcrumbs,
  getScreeningStatusBeacons
} from '@/helpers/newTicketHelpers'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { STEP_SCAN_BEACON } from '@/constants/ticketSteps'
import { selectEmbryoTypeLabel } from '@/utils'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import { isVaultByUnitId } from '@/helpers/cryoBeaconLocation'

export default {
  name: 'label-scan',
  setup() {
    const {
      ticket,
      beacons,
      isEMRTicket,
      activeBeacon,
      replaceBeaconInBeaconList,
      updateTicketBeacons,
      saveTicket
    } = useTicket()
    return {
      ticket,
      beacons,
      isEMRTicket,
      activeBeacon,
      replaceBeaconInBeaconList,
      updateTicketBeacons,
      saveTicket
    }
  },
  data() {
    return {
      modalEdit: [SPECIMEN_NOTES, BIOPSY_RESULT, 'freeText'],
      cryobeacon: CRYOBEACON,
      loaderMessage: `Verifying ${CRYOROBOT} space availability`,
      isLoaderVisible: false,
      isSpaceAvailableInSelectedUnit: true,
      CRYODEVICE,
      CRYOBEACON
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== STEP_SCAN_BEACON) {
      store.commit('beaconsModule/clearScannedBeaconBarcodeNumbers')
    }
    next()
  },
  mounted() {
    if (this.activeBeacon) {
      const updatedActiveBeacon = {
        ...this.activeBeacon,
        cryoDevice: this.activeBeacon.cryoDevice.map((cryoDevice) => ({
          ...cryoDevice,
          cryodeviceBarcodeValidation: undefined,
          cryodeviceBarcode: cryoDevice.originalCryodeviceBarcode || cryoDevice.cryodeviceBarcode
        }))
      }
      this.activeBeacon = updatedActiveBeacon
    }
  },
  watch: {
    activeBeacon() {
      // eslint-disable-next-line no-unused-expressions
      this.activeBeacon?.cryoDevice
        .sort((a, b) => a.cryodeviceBarcode - b.cryodeviceBarcode)
        .forEach(({ cryodeviceBarcode }, index) => {
          // eslint-disable-next-line no-unused-expressions
          this.beacons?.[0]?.cryoDevice.map(
            ({
              cryodeviceBarcode: beaconCryodeviceBarcode,
              embryo,
              oocyte,
              specimenInfo,
              specimenType
            }) => {
              switch (specimenType) {
                case SPECIMEN_TYPE_EMBRYO:
                  if (cryodeviceBarcode === beaconCryodeviceBarcode) {
                    this.activeBeacon.cryoDevice[index].embryo = embryo
                    this.activeBeacon.cryoDevice[index].specimenInfo = specimenInfo
                  }
                  break
                case SPECIMEN_TYPE_EGG:
                  if (cryodeviceBarcode === beaconCryodeviceBarcode) {
                    this.activeBeacon.cryoDevice[index].oocyte = oocyte
                    this.activeBeacon.cryoDevice[index].specimenInfo = specimenInfo
                  }
                  break
                default:
                  break
              }
              return this
            }
          )
        })
      this.beacons = this.replaceBeaconInBeaconList(this.beacons, this.activeBeacon)
      this.ticket = this.updateTicketBeacons(this.ticket, this.beacons)
      this.saveTicket(this.ticket)
      this.setBeacons(this.beacons)
    }
  },
  computed: {
    ...mapGetters('newTicketModule', [
      'currentTicketStep',
      'selectedMethod',
      'selectedPatient',
      'selectedSpecimenType',
      'selectedCryoDeviceType',
      'procedureDateFrom',
      'source'
    ]),
    ...mapGetters('ticketsModule', ['destinationOptions', 'selectedDestination']),

    getCurrentDestination() {
      if (this.selectedDestination) {
        return this.destinationOptions.find(
          (destination) => destination.containerId === parseInt(this.selectedDestination)
        )['robotName']
      }
      return this.destinationOptions.find((destination) => destination.defaultRobot)['robotName']
    },

    showShelfLabel() {
      return (
        isVaultByUnitId(this.selectedDestination) && this.activeBeacon.selectedShelf !== OPTIONAL
      )
    },

    getCurrentShelf() {
      return this.activeBeacon.selectedShelf
    },

    cryodevicesIdChecked() {
      if (this.activeBeacon.cryoDevice) {
        return this.activeBeacon.cryoDevice
          .map((cryoDev) => cryoDev.cryodeviceBarcodeValidation)
          .every((cryoDevValidation) => cryoDevValidation)
      }
      return false
    },
    getActiveBreadcrumbs() {
      const currentBreadcrumbs = getBreadcrumbs({
        currentTicketStep: this.currentTicketStep,
        selectedMethod: this.selectedMethod
      })

      return currentBreadcrumbs
    },
    computeActiveBeaconCounterMsg() {
      return activeBeaconCounterMsg(this.beacons, false)
    },
    cryoDeviceTypeValue() {
      return this.selectedCryoDeviceType?.value || ''
    },
    activeTicket() {
      return {
        procedureDateFrom: this.procedureDateFrom,
        patient: this.selectedPatient,
        type: this.selectedSpecimenType,
        identificationNumber: this.selectedPatient[0].identificationNumber
      }
    },
    cryodeviceLabelsData() {
      const { cryoDevice } = this.activeBeacon

      if (!cryoDevice) {
        return []
      }

      const { identificationNumber, firstName, lastName } = this.selectedPatient[0]

      const updatedCryoDevices = cryoDevice
        .map((currentCryoDevice) => {
          const cryodeviceTypeId = Number(this.selectedCryoDeviceType.id)
          const procedureDateFrom = dayjs(this.procedureDateFrom).format('DDMMMYYYY').toUpperCase()
          const embryoTypeLabel = selectEmbryoTypeLabel(
            {
              embryo: {
                ...currentCryoDevice.embryo,
                embryoType: currentCryoDevice.embryo?.embryoType
              }
            },
            this.embryoTypes
          )

          return {
            ...currentCryoDevice,
            cryodeviceTypeId,
            identificationNumber,
            firstName,
            lastName,
            procedureDateFrom,
            embryoTypeLabel
          }
        })
        .sort((a, b) => a.cryodeviceBarcode - b.cryodeviceBarcode)
      return updatedCryoDevices
    }
  },
  methods: {
    ...mapActions('spinnerModule', ['displaySpinner', 'hideSpinner']),
    ...mapActions('newTicketModule', ['setRobotTransfer']),
    ...mapMutations('ticketModule', ['setBeacons', 'setTicketRobotTransfer']),
    async onClickNext() {
      this.displaySpinner('Validating Patient Inventory')

      try {
        const beaconResponse = await getScreeningStatusBeacons({
          beacons: this.beacons,
          patientId: this.selectedPatient[0].globalPatientNumber
        })

        this.hideSpinner()

        if (beaconResponse === EXISTING_BEACON_STEP) {
          this.handleNextStep()
          return
        }
        // Is not add to existing beacon, set robot transfer as IN!
        await this.setTicketRobotTransfer(ROBOT_TRANSFER_IN)
        this.setRobotTransfer(ROBOT_TRANSFER_IN)
      } catch (error) {
        this.hideSpinner()
        return
      }

      this.handleNextStep({ isScanBeacon: true })
    },
    async handleNextStep({ isScanBeacon = false } = {}) {
      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        beacons: this.beacons,
        isScanBeacon
      })
      this.$router?.push({ name: nextStepPath })
    },
    handleClickBack() {
      const previousStepPath = executePreviousTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep
      })
      this.$router.replace({ name: previousStepPath })
    },
    handleSpecimenChange(beaconSpecimens) {
      const updatedActiveBeacon = {
        ...this.activeBeacon,
        cryoDevice: [...beaconSpecimens]
      }
      this.activeBeacon = updatedActiveBeacon
    }
  },
  components: {
    TopHeader,
    HeaderInfo,
    MainContainer,
    ActionBar,
    DynamicTitle,
    BreadCrumbs,
    EditableTable,
    ScreeningStatusLabel,
    ButtonDynamic,
    TicketStatusTags,
    CryobeaconNumber,
    LoadingUi
  }
}
</script>
