import { useStore } from 'vuex'
import { StoreModuleNames } from '@/store'
import { StoreMutations } from '@/store/store-types'

const useMutations = <T = any>(namespace: StoreModuleNames | null = null) => {
  const store: any = useStore()
  const mapName = (mutation) => {
    if (!namespace) {
      return mutation
    } else {
      return mutation.replace(`${namespace}/`, '')
    }
  }
  return Object.fromEntries(
    Object.keys(store._mutations)
      .filter((mutation) => {
        if (namespace) {
          return mutation.includes(`${namespace}/`)
        }
        return true
      })
      .map((mutation) => [mapName(mutation), (value) => store.commit(mutation, value)])
  ) as StoreMutations<T>
}

export default useMutations
