<template>
  <MainContainer gridSlots="3">
    <loading-ui v-if="isLoading" modal message="Retrieving Specimens Data" />
    <top-header @backStep="handleClickBack">
      <HeaderInfo />
    </top-header>
    <ActionBar data-test="select-specimens__action-bar" colsDistribution="9/3">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left">{{ headerMessage }}</dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnDataTest="select-specimens-next-btn"
          class="select-specimens__btn-next"
          btnText="Next"
          btnType="button"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="arrow-circle-right"
          :isDisabled="!isSpecimenSelected"
          @click="handleSelectSpecimens"
        />
      </template>
    </ActionBar>
    <div class="main-container main-container--actions">
      <div class="main-container__body">
        <Breadcrumbs v-if="isExport" :items="breadcrumbsItems" />
        <h3
          class="no-specimens-title w-full h-full flex items-center justify-center text-2xl text-white"
          v-else-if="hasNoSpecimens"
        >
          This patient has no Specimens of this type stored. A {{ selectedMethod }} Ticket cannot be
          created.
        </h3>
        <h3
          class="no-specimens-title w-full h-full flex items-center justify-center text-2xl text-white"
          v-else-if="hasSpecimenErrorData"
        >
          There was an error requesting the data
        </h3>
        <div
          data-test="specimens-table-list"
          id="specimens-table-list"
          class="specimens-table-list"
          v-if="hasTableHeaders"
        >
          <div
            v-if="isExport && (numberSpecimensOocytes > 0 || numberSpecimensEmbryos > 0)"
            data-test="tabs-container"
            class="flex justify-between"
          >
            <div data-test="tabs-container-left" class="flex items-end">
              <Tabs
                :options="openSpecimensOptions"
                :active="selectedSpecimenTypeTab"
                @changeValue="handleTabChange"
              />
            </div>
            <div data-test="export-specimen-count" class="text-xl text-white leading-6 mr-2">
              {{ specimenCountMessage }}
            </div>
          </div>
          <!-- @vue-ignore -->
          <ScrollTableComponent
            data-test="specimens-table"
            v-model="specimenModel"
            :selectionMode="'checkbox'"
            :headers="headers"
            minHeight="h-full"
            singleSelection
            :hasTabs="isExport"
            hideSelectAllCheckbox
            ignore-select-all-procedure-check
            @update:modelValue="handleTableChange"
          />
          <p
            v-if="isExport && isSpecimenSelected"
            data-test="selected-specimens-count"
            class="text-white py-3"
          >
            {{ selectedSpecimensMessage }}
          </p>
        </div>
      </div>
    </div>
  </MainContainer>
</template>

<script setup lang="ts">
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import Breadcrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import * as specimensHeaders from '@/constants/table-headers/select-specimens'
import { selectEmbryoTypeLabel } from '@/utils'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import {
  CRYODEVICES,
  METHOD_EXPORT,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_EGGS_UPPERCASE,
  SPECIMEN_TYPE_EGG_UPPERCASE,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_OOCYTE_UPPERCASE,
  SPECIMEN_TYPE_OOCYTES,
  SPECIMEN_TYPE_EMBRYOS
} from '@/constants'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { buildSpecimenSourcesValueObject } from '@/helpers/specimenHelpers'
import { computed, onMounted, ref, watch } from 'vue'
import useGetters from '@/composables/useGetters'
import useActions from '@/composables/useActions'
import { useRouter } from 'vue-router'
import { Beacon } from '@/types/beacon'

type Specimens = {
  cryodeviceBarcode: string
  specimenCount: number
  specimenType: string
  beaconBarcode: string
  embryo: {}
  oocyte: {}
}

const router = useRouter()
const specimenModel = ref({
  sort: {
    orderBy: 'specimenId',
    direction: 'asc'
  },
  options: []
})
const selectedSpecimens = ref<Specimens[]>([])
const allSpecimens = ref<Specimens[]>([])
const localSpecimens = ref<Specimens[]>([])
const selectedBeacons = ref<Beacon[]>([])
const isLoading = ref(false)
const currentSpecimenType = ref()
const selectedSpecimenTypeTab = ref(SPECIMEN_TYPE_EMBRYO)

const { specimens, embryoTypes } = useGetters('specimensModule')
const { currentTicketStep, selectedMethod, selectedPatient, selectedSpecimenType } =
  useGetters('newTicketModule')
const breadcrumbsItems = computed(() => {
  return [
    {
      label: 'Specimens',
      active: true
    },
    {
      label: 'Reason',
      active: false
    },
    {
      label: 'Confirm',
      active: false
    }
  ]
})
const isExport = computed(() => {
  return selectedMethod.value === METHOD_EXPORT
})
const headerMessage = computed(() => {
  return `Which Specimen(s) would you like to ${selectedMethod.value.toLowerCase()}?`
})
const specimenCountMessage = computed(() => {
  const totalSpecimens = numberSpecimensEmbryos.value + numberSpecimensOocytes.value

  return `${totalSpecimens} Specimens and ${allSpecimens.value.length} Cryodevices Total`
})
const headers = computed(() => {
  return specimensHeaders[selectedSpecimenTypeTab.value.toLowerCase()]
})
const isSpecimenSelected = computed(() => {
  return selectedSpecimens.value.length > 0
})
const hasNoSpecimens = computed(() => {
  return specimens.value.status === 'ok' && allSpecimens.value.length === 0
})
const hasSpecimenErrorData = computed(() => {
  return specimens.value.status === 'error'
})
const hasTableHeaders = computed(() => {
  return headers.value.length
})
const selectedSpecimensMessage = computed(() => {
  const embryos = selectedSpecimens.value.filter(
    (specimen) => specimen.specimenType === SPECIMEN_TYPE_EMBRYO
  )
  const embryoCount = embryos.reduce((count, current) => count + Number(current.specimenCount), 0)

  const oocytes = selectedSpecimens.value.filter(
    (specimen) => specimen.specimenType !== SPECIMEN_TYPE_EMBRYO
  )
  const oocyteCount = oocytes.reduce((count, current) => count + Number(current.specimenCount), 0)

  return `${embryoCount} Embryo${embryoCount === 1 ? '' : 's'} and ${oocyteCount} Oocyte${
    oocyteCount === 1 ? '' : 's'
  } Selected`
})
const embryoCryodevices = computed(() => {
  const embryoCryodevices = allSpecimens.value.filter(
    (specimen) => specimen.specimenType === SPECIMEN_TYPE_EMBRYO
  )

  return embryoCryodevices
})
const oocyteCryodevices = computed(() => {
  const oocyteCryodevices = allSpecimens.value.filter(
    (specimen) => specimen.specimenType !== SPECIMEN_TYPE_EMBRYO
  )

  return oocyteCryodevices
})
const numberSpecimensEmbryos = computed(() => {
  const embryoCount = embryoCryodevices.value.reduce(
    (count, current) => count + Number(current.specimenCount),
    0
  )
  return embryoCount
})
const numberSpecimensOocytes = computed(() => {
  const oocyteCount = oocyteCryodevices.value.reduce(
    (count, current) => count + Number(current.specimenCount),
    0
  )
  return oocyteCount
})
const openSpecimensOptions = computed(() => {
  const getSpecimensCountLabel = (specimens, devices) => {
    let label = `${specimens} Total`
    if (specimens) {
      label += ` on ${devices} ${CRYODEVICES}`
    }
    return label
  }

  const embryoDevicesCount = embryoCryodevices.value.length
  const oocyteDevicesCount = oocyteCryodevices.value.length

  return [
    {
      embryoDevicesCount,
      value: SPECIMEN_TYPE_EMBRYO,
      label: SPECIMEN_TYPE_EMBRYOS,
      count: getSpecimensCountLabel(numberSpecimensEmbryos.value, embryoDevicesCount),
      disabled: !numberSpecimensEmbryos.value
    },
    {
      oocyteDevicesCount,
      value: SPECIMEN_TYPE_EGG,
      label: SPECIMEN_TYPE_OOCYTES,
      count: getSpecimensCountLabel(numberSpecimensOocytes.value, oocyteDevicesCount),
      disabled: !numberSpecimensOocytes.value
    }
  ]
})

const { fetchSpecimensEnabled } = useActions('specimensModule')
const handleClickBack = () => {
  const previousStepPath = executePreviousTicketStep({
    selectedMethod: selectedMethod.value,
    currentTicketStep: currentTicketStep.value
    // fromRouteName: fromRouteName.value
  })

  router.replace({ name: previousStepPath })
}
const handleSelectSpecimens = async () => {
  const nextStepPath = await executeNextTicketStep({
    selectedMethod: selectedMethod.value,
    currentTicketStep: currentTicketStep.value,
    beacons: selectedBeacons.value
  })

  router.push({ name: nextStepPath })
}
const handleTableChange = async (newValue) => {
  const currentSpecimens = localSpecimens.value.map((localItem) => {
    const foundItem = newValue.options.find(
      (selectedItem) => localItem.cryodeviceBarcode === selectedItem.cryodeviceBarcode
    )

    if (foundItem) {
      return {
        ...localItem,
        ...foundItem
      }
    }

    return localItem
  })

  localSpecimens.value = currentSpecimens
  selectedSpecimens.value = currentSpecimens.filter((selectedSpecimen) => selectedSpecimen.selected)

  const currentBeacons: Beacon[] = selectedSpecimens.value.reduce((acc, specimen) => {
    const specimenWithTypeInfo = {
      ...specimen,
      ...(specimen.specimenType === SPECIMEN_TYPE_EMBRYO && { ...specimen.embryo }),
      ...(specimen.specimenType === SPECIMEN_TYPE_EGG && { ...specimen.oocyte })
    }

    const existingBeacon = acc.find((item) => item.beaconBarcode === specimen.beaconBarcode)

    if (existingBeacon) {
      const filteredAcc = acc.filter((beac) => beac.beaconBarcode !== specimen.beaconBarcode)

      return [
        ...filteredAcc,
        {
          ...existingBeacon,
          cryoDevice: [...existingBeacon.cryoDevice, specimenWithTypeInfo]
        }
      ]
    }

    return [
      ...acc,
      {
        beaconBarcode: specimen.beaconBarcode,
        cryoDevice: [specimenWithTypeInfo]
      }
    ]
  }, [] as any[])

  selectedBeacons.value = currentBeacons
}
const handleTabChange = (option) => {
  selectedSpecimenTypeTab.value = option.value
}
const applySourcesValueObjectToSpecimens = (specimens) => {
  return specimens.map((specimen) => ({
    ...specimen,
    sourcesValue: buildSpecimenSourcesValueObject(specimen)
  }))
}

watch(specimens, () => {
  const enabledSpecimens = specimens.value.all.filter((specimen) => !specimen.disabled)
  const formattedSpecimens = enabledSpecimens.map((specimen) => ({
    ...specimen,
    cryoDate: Number(specimen.cryoDate),
    embryoTypeLabel: selectEmbryoTypeLabel(specimen, embryoTypes.value)
  }))

  allSpecimens.value = formattedSpecimens
  localSpecimens.value = formattedSpecimens
})
watch(allSpecimens, async () => {
  const filteredSpecimensByType = allSpecimens.value.filter(
    (specimen) =>
      specimen.specimenType.toLowerCase() === selectedSpecimenTypeTab.value.toLowerCase()
  )

  specimenModel.value = {
    ...specimenModel.value,
    options: applySourcesValueObjectToSpecimens(filteredSpecimensByType)
  }
})
watch(selectedSpecimenTypeTab, (newTabValue) => {
  if (newTabValue) {
    const filteredSpecimensByType = localSpecimens.value.filter(
      (specimen) =>
        specimen.specimenType.toLowerCase() === selectedSpecimenTypeTab.value.toLowerCase()
    )

    specimenModel.value = {
      ...specimenModel.value,
      options: applySourcesValueObjectToSpecimens(filteredSpecimensByType)
    }
  }
})

onMounted(async () => {
  const specimenTypeUpperCase = selectedSpecimenType.value?.toUpperCase()

  currentSpecimenType.value =
    specimenTypeUpperCase === SPECIMEN_TYPE_EGGS_UPPERCASE ||
    specimenTypeUpperCase === SPECIMEN_TYPE_OOCYTE_UPPERCASE
      ? SPECIMEN_TYPE_EGG_UPPERCASE
      : specimenTypeUpperCase

  selectedSpecimenTypeTab.value = currentSpecimenType.value
    ? currentSpecimenType.value
    : SPECIMEN_TYPE_EMBRYO

  isLoading.value = true

  const patientIsNotNull = Boolean(Object.keys(selectedPatient.value[0]).length)
  if (patientIsNotNull) {
    const patientIds = selectedPatient.value.map((patient) => patient.globalPatientNumber)
    await fetchSpecimensEnabled({
      patientIds,
      specimenType: currentSpecimenType.value,
      includeChildTickets: true
    })
  }

  isLoading.value = false
})
</script>
