<template>
  <MainContainer data-test="select-patient" :gridSlots="hasPatients ? 'select-patient' : '6'">
    <loading-ui v-if="isLoadingSpecimensAvailability" :message="CHECKING_FOR_OPEN_TICKETS" modal />
    <top-header @backStep="handleClickBack">
      <HeaderInfo />
    </top-header>
    <ActionBar data-test="select-patient__action-bar" colsDistribution="9/3" class="mb-4">
      <template v-slot:left-side>
        <message-card class="float-left">
          <span>{{ ticketMessage }}</span>
        </message-card>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnDataTest="select-patient__select-button"
          btnType="button"
          btnText="Select"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="arrow-circle-right"
          :isDisabled="!isPatientSelectionValid"
          @click="handleSelectPatient"
        />
      </template>
    </ActionBar>
    <PatientSearch
      :canCreatePatient="allowCreatePatients && !isThawOrExport"
      :selectedPatients="selectedPatients"
      :canImportPatient="isFreezeOrImport"
      :selectionMode="selectionMode"
      @setPatients="updatePatients"
    />
    <section v-if="patientHasNoSpecimensAvailable">
      <p class="text-white text-lg">
        This patient has no Specimens of this type stored. A {{ selectedMethod }} Ticket cannot be
        created.
      </p>
    </section>
    <SelectedPatients data-test="select-patient__selected-patients" v-if="showSelectedPatients" />
  </MainContainer>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import SelectedPatients from '@/components/SelectedPatients/SelectedPatients.vue'
import {
  CHECKING_FOR_OPEN_TICKETS,
  NO_AVAILABLE_SPECIMENS_ERROR,
  METHOD_EXPORT,
  METHOD_THAW,
  METHOD_FREEZE,
  METHOD_IMPORT,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_EGGS_UPPERCASE,
  SPECIMEN_TYPE_EGG_UPPERCASE,
  SPECIMEN_TYPE_OOCYTE,
  SPECIMEN_TYPE_OOCYTE_UPPERCASE
} from '@/constants'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import PatientSearch from '@/components/PatientSearch/PatientSearch.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import MessageCard from '@/components/MessageCard/MessageCard.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import toast from '@/config/toast'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { isFeatureEnabled, MULTIPLE_PATIENTS_ENABLED_FF } from '@/helpers/featureFlags'

export default {
  name: 'select-patient',
  data() {
    return {
      fromRouteName: null,
      allowCreatePatients: this.$isSiteEmrEnabled?.allowCreatePatients,
      isLoadingSpecimensAvailability: false,
      isPatientSearchSubmitted: false,
      foundPatients: [],
      selectedPatient: [],
      CHECKING_FOR_OPEN_TICKETS
    }
  },
  async created() {
    // Needed for when user re-enters the page
    await this.resetPatients()
  },
  beforeRouteEnter(to, from, next) {
    // we need to account for multiple screens that route to ScanBeacon, in
    // order to be able to route back (eg.: click back button)
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called
    next((vm) => {
      // access to component instance via `vm`
      // eslint-disable-next-line no-param-reassign
      vm.fromRouteName = from.name
    })
  },
  computed: {
    ...mapGetters('newTicketModule', [
      'currentTicketStep',
      'selectedMethod',
      'selectedSpecimenType'
    ]),
    ...mapGetters('patientsModule', ['patientsFound']),
    ...mapGetters('patientGroupModule', { selectedPatients: 'patients' }),
    ...mapGetters('patientGroupModule', [
      'primaryPatientId',
      'primaryPatient',
      'totalSharedCryoDevices'
    ]),
    ...mapGetters('specimensModule', ['specimens']),
    ticketMessage() {
      if (this.selectedSpecimenType) {
        return `Which Patient is this ${this.selectedSpecimenType} ${this.selectedMethod} for?`
      }
      return `Which Patient is this ${this.selectedMethod} for?`
    },
    isExport() {
      return this.selectedMethod === METHOD_EXPORT
    },
    isThaw() {
      return this.selectedMethod === METHOD_THAW
    },
    isFreezeOrImport() {
      return this.selectedMethod === METHOD_FREEZE || this.selectedMethod === METHOD_IMPORT
    },
    isThawOrExport() {
      return this.isThaw || this.isExport
    },
    patientHasNoSpecimensAvailable() {
      if (!this.isMultiplePatientsEnabled && this.isThaw) {
        const hasLoadedSpecimens = this.hasSelectedPatients && this.specimens?.status === 'ok'
        return hasLoadedSpecimens && !this.specimens?.all?.length
      }
      return false
    },
    hasPatients() {
      return !!this.foundPatients.length
    },
    hasSelectedPatients() {
      if (this.isMultiplePatientsEnabled) {
        return Boolean(this.selectedPatients.length)
      }

      return Boolean(this.selectedPatient.length)
    },
    isMultiplePatientsEnabled() {
      const multiplePatientsEnabled = isFeatureEnabled(MULTIPLE_PATIENTS_ENABLED_FF)
      return multiplePatientsEnabled
    },
    selectionMode() {
      if (this.isMultiplePatientsEnabled) {
        return 'none'
      }

      return 'radio'
    },
    showSelectedPatients() {
      if (!this.isMultiplePatientsEnabled) {
        return false
      }
      return this.hasSelectedPatients
    },
    hasSharedEmbryos() {
      const { embryoCount } = this.totalSharedCryoDevices
      return Boolean(embryoCount)
    },
    hasSharedEggs() {
      const { oocyteCount } = this.totalSharedCryoDevices
      return Boolean(oocyteCount)
    },
    isEnabledForThawAndExport() {
      const isEmbryo = this.selectedSpecimenType === SPECIMEN_TYPE_EMBRYO
      const isEgg =
        this.selectedSpecimenType === SPECIMEN_TYPE_EGG ||
        this.selectedSpecimenType === SPECIMEN_TYPE_OOCYTE

      if (this.selectedPatients.length > 1) {
        if (isEmbryo) {
          return this.hasSharedEmbryos && this.hasAvailableSpecimen
        }

        if (isEgg) {
          return this.hasSharedEggs && this.hasAvailableSpecimen
        }
      }

      return this.hasAvailableSpecimen
    },
    isPatientSelectionValid() {
      if (this.isMultiplePatientsEnabled) {
        if (this.isThaw || this.isExport) {
          return this.hasSelectedPatients && this.isEnabledForThawAndExport
        }
        return this.hasSelectedPatients
      }
      return this.hasSelectedPatients && !this.patientHasNoSpecimensAvailable
    },
    currentSpecimenType() {
      if (!this.selectedSpecimenType) return undefined
      const specimenTypeUpperCase = this.selectedSpecimenType.toUpperCase()
      const eggTypes = [SPECIMEN_TYPE_EGGS_UPPERCASE, SPECIMEN_TYPE_OOCYTE_UPPERCASE]
      const isEgg = eggTypes.includes(specimenTypeUpperCase)
      if (isEgg) {
        return SPECIMEN_TYPE_EGG_UPPERCASE
      }
      return specimenTypeUpperCase
    },
    hasAvailableSpecimen() {
      return this.specimens.all.some((specimen) => !specimen.disabled)
    }
  },
  methods: {
    ...mapActions('patientsModule', ['searchPatients']),
    ...mapActions('specimensModule', ['fetchSpecimens', 'fetchSpecimensEnabled', 'resetSpecimens']),
    ...mapActions('patientGroupModule', ['addPatient', 'resetPatients', 'setPatients']),
    ...mapMutations('ticketModule', ['setPatient']),
    ...mapMutations('ticketModule', ['setPatient', 'setGlobalPatientNumber']),
    handleClickBack() {
      const previousStepPath = executePreviousTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        specimenType: this.selectedSpecimenType
      })
      this.$router.replace({ name: previousStepPath })
    },
    closeModalWindow() {
      this.isNewPatientModalOpen = false
    },
    openModalWindow() {
      this.isNewPatientModalOpen = true
    },
    handleAddPatient({ value }) {
      const patient = this.patientsModel.options.find(
        (item) => item.globalPatientNumber === value.id
      )

      if (patient) {
        this.addPatient(patient)
      }
    },
    updatePatients(newPatients) {
      this.foundPatients = newPatients
    },
    async handleSelectPatient() {
      let patients = this.selectedPatient
      if (this.isMultiplePatientsEnabled) {
        const sharedPatients = this.selectedPatients.filter(
          (patient) => patient.globalPatientNumber !== this.primaryPatientId
        )
        patients = [this.primaryPatient, ...sharedPatients]
      }
      const [patient] = patients
      this.setPatient(patient)
      this.setGlobalPatientNumber(patient.globalPatientNumber)

      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        patients
      })

      this.$router.push({ name: nextStepPath })
    }
  },
  watch: {
    foundPatients(newValue, oldValue) {
      if (newValue !== oldValue) {
        const currentSelectedPatient = this.foundPatients.filter((option) => option.selected)

        this.selectedPatient = currentSelectedPatient
        this.resetSpecimens()
        if (currentSelectedPatient.length > 0) {
          this.fetchSpecimens({
            patientIds: [currentSelectedPatient[0].globalPatientNumber],
            specimenType: this.currentSpecimenType
          })
        }
      }
    },
    async selectedPatients(newSelectedPatients) {
      const hasSelectedPatients = newSelectedPatients.length
      const shouldValidateAvailableSpecimens = this.isThawOrExport && hasSelectedPatients

      if (shouldValidateAvailableSpecimens) {
        const patientIds = newSelectedPatients.map((patient) => patient.globalPatientNumber)
        this.isLoadingSpecimensAvailability = true
        const specimenType = this.currentSpecimenType
        await this.fetchSpecimensEnabled({ patientIds, specimenType, includeChildTickets: true })
        if (!this.hasAvailableSpecimen) {
          toast.error({ title: NO_AVAILABLE_SPECIMENS_ERROR(this.selectedMethod) })
        }
        this.isLoadingSpecimensAvailability = false
      }
    }
  },
  components: {
    ActionBar,
    ButtonDynamic,
    HeaderInfo,
    LoadingUi,
    MainContainer,
    MessageCard,
    PatientSearch,
    TopHeader,
    SelectedPatients
  }
}
</script>
