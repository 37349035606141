const defaultState = {
  selectedTickets: [[]],
  ticketDetail: {
    beaconBarcode: '',
    specimens: []
  },
  selectedViewMode: ['normal'],
  viewTicket: [
    {
      tickettype: 'general',
      ticketId: null
    }
  ],
  error: null,
  status: 'completed'
}

export default {
  setTicketDetail(state, selectedTickets) {
    state.ticketDetail = selectedTickets[0] || defaultState.ticketDetail
  },
  addNewTicketInfo(state, resp) {
    // Add the new info to the ticket
    state.selectedTickets = [
      state.selectedTickets[0].map((ticket) => {
        const newInfo = resp.find((elem) => {
          if (typeof ticket.ticketId !== 'undefined') {
            return elem.ticketId === ticket.ticketId
          }
          return elem.ticketId === ticket
        })

        return {
          ...ticket,
          ...newInfo
        }
      }),
      ...state.selectedTickets.slice(1)
    ]

    // $ss.setFieldSessionStorage('selectedTickets', state.selectedTickets, 'process')
  },
  loading(state) {
    state.status = 'loading'
  },
  success(state) {
    state.status = 'success'
  },
  error(state, payload) {
    state.status = payload
  },
  clearSelectedTickets(state) {
    state.selectedTickets = [[]]
    state.ticketDetail = defaultState.ticketDetail
  },
  setSelectedTickets(state, selectedTickets) {
    state.selectedTickets = [selectedTickets]
  },
  pushSelectedTickets(state, selectedTickets) {
    state.selectedTickets = [selectedTickets, ...state.selectedTickets]
  },
  popSelectedTickets(state) {
    const sliced = state.selectedTickets.slice(1)
    state.selectedTickets = sliced.length ? sliced : defaultState.selectedTickets
  },
  setSelectedViewMode(state, viewMode) {
    state.selectedViewMode = [viewMode]
  },
  pushSelectedViewMode(state, viewMode) {
    state.selectedViewMode = [viewMode, ...state.selectedViewMode]
  },
  popSelectedViewMode(state) {
    const sliced = state.selectedViewMode.slice(1)
    state.selectedViewMode = sliced.length ? sliced : defaultState.selectedViewMode
  },
  setViewTicket(state, viewTicket) {
    state.viewTicket = [viewTicket]
  },
  pushViewTicket(state, viewTicket) {
    state.viewTicket = [viewTicket, ...state.viewTicket]
  },
  popViewTicket(state) {
    const sliced = state.viewTicket.slice(1)
    state.viewTicket = sliced.length ? sliced : defaultState.viewTicket
  }
}
