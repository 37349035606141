<template>
  <MainContainer data-test="details-view" grid-slots="3">
    <loading-ui v-if="isLoaderVisible" modal message="Validating selected unit space" />
    <top-header @backStep="handleClickBack">
      <HeaderInfo />
    </top-header>
    <ActionBar data-test="details__action-bar" cols-distribution="9/3">
      <template v-slot:left-side>
        <dynamic-title title-type="h2" float-to="left">
          Please record the details of the Cryodevices
        </dynamic-title>
        <TicketStatusTags :is-emr="isEMRTicket" />
      </template>
      <template v-slot:right-side>
        <button-dynamic
          v-if="isMethodFreeze"
          data-test="details__outside-labels-button"
          btn-type="button"
          btn-style="secondary"
          btn-text="Non-ivfOS Cryolabel"
          show-icon
          addMarginRight
          font-awesome-icon-class="tag"
          :is-disabled="isButtonDisabled"
          @click="handleOutsideLabels"
        />
        <button-dynamic
          data-test="details__print-labels-button"
          btn-type="button"
          btn-style="primary"
          :btn-text="buttonText"
          :is-disabled="isButtonDisabled"
          show-icon
          :font-awesome-icon-class="buttonIcon"
          @click="handleBeaconUpdate"
        />
      </template>
    </ActionBar>
    <section>
      <BreadCrumbs :items="getActiveBreadcrumbs" />
      <div class="w-full rounded-tl-md rounded-tr-md bg-white py-2 px-4">
        <div class="flex">
          <div class="mr-6">
            <CustomSelect
              dataTest="label-scan__destination"
              class="bg-tmrw-green-light p-2 rounded-lg w-54"
              customText="STORE TO"
              :options="destinationOptions"
              isDefaultOptionDisabled
              hasLargePadding
              @update:modelValue="onChangeDestination"
              :modelValue="defaultDestinationValue"
            />
          </div>
          <div v-if="isVaultRobotSelected" class="ml-4 mr-8">
            <CustomSelect
              dataTest="label-scan__destination-shelf"
              class="bg-tmrw-green-light p-2 rounded-lg w-54"
              customText="SHELF ASSIGNMENT"
              :options="vaultShelfOptions"
              isDefaultOptionDisabled
              hasLargePadding
              @update:modelValue="onChangeShelf"
              :modelValue="selectedShelf"
            />
          </div>
          <div class="flex items-start justify-center flex-col mr-6">
            <p
              data-test="cryobeacon-count-title"
              class="text-tmrw-blue uppercase text-xs font-bold -mt-1"
            >
              {{ CRYOBEACON }} count
            </p>
            <CryobeaconNumber
              :activeBeaconCounter="computeActiveBeaconCounterMsg"
              :showBackground="false"
              titleType="h1-blue-light-medium-bold"
              customPadding="p-0"
            />
          </div>
          <div class="text-tmrw-blue flex items-start justify-center flex-col mr-6">
            <p class="uppercase text-xs font-bold">Infectious Screening Status</p>
            <!-- @vue-ignore -->
            <ScreeningStatusLabel
              :id="cryodeviceLabelsData[0]?.screeningStatus"
              class="pr-6 font-semibold text-tmrw-blue"
              text-color="yellow"
              icon-color="yellow"
              :is-full-width="false"
            />
          </div>
          <div class="flex items-start justify-center flex-col mr-6">
            <p
              class="text-tmrw-blue font-semibold mb-2"
              data-test="details__action-bar--cryodevice-type-title"
            >
              {{ CRYODEVICE }} Type
            </p>
            <p class="text-tmrw-blue mb-2" data-test="details__action-bar--cryodevice-type">
              {{ cryoDeviceTypeValue }}
            </p>
          </div>
          <div
            v-if="isMethodImport"
            class="col-span-2 flex items-start justify-center flex-col mr-6"
          >
            <p class="text-tmrw-blue font-semibold mb-2" data-test="details__action-bar--donor-id">
              Donor Id
            </p>
            <p class="text-tmrw-blue mb-2" data-test="details__action-bar__donor-id">
              {{ currentDonorId }}
            </p>
          </div>
          <div
            v-if="isMethodImport"
            class="col-span-2 flex items-start justify-center flex-col mr-6"
          >
            <p
              class="text-tmrw-blue font-semibold mb-2"
              data-test="details__action-bar--shipping-source"
            >
              Shipping Source
            </p>
            <p class="text-tmrw-blue mb-2" data-test="details__action-bar__shipping-source">
              {{ currentClinicName }}
            </p>
          </div>
        </div>
      </div>
      <!-- @vue-ignore -->
      <EditableTable
        v-if="cryodeviceLabelsData.length"
        :items="cryodeviceLabelsData"
        :edit="isDetailEditable"
        :modalEdit="modalEdit"
        :method-is-import="isMethodImport"
        :specimen-type="selectedSpecimenType"
        :field-errors="!areMandatoryFieldsValid()"
        showCryodeviceValueOnly
        class="p-2 bg-white"
        tab-direction="column"
        @changeValue="handleSpecimenChange"
      />
    </section>
    <preview-labels
      :show-label-modal="showPrintLabelModal"
      :labels="cryodeviceLabelsData"
      @closeLabelModal="handleClosePrintModal"
      @clickPrint="handleContinue"
    />
    <ModalWindow
      modal-title="Use Non-ivfOS Cryolabels"
      title-type="h2-blue-dark"
      cols-distribution="9/3"
      :isModalOpen="isModalOpen"
      is-bold
      @closeModalWindow="closeModalWindow"
    >
      <p class="my-8 text-tmrw-blue text-2xl">
        A scannable barcode must be present to use a<br />Non-ivfOS Cryolabel
      </p>
      <button-dynamic
        btnText="Continue"
        btnType="button"
        btnStyle="primary"
        @click="handleConfirmOutsideLabels"
      />
      <button-dynamic
        btnText="Cancel"
        btnType="button"
        btnStyle="secondary"
        addMarginRight
        @click="closeModalWindow"
      />
    </ModalWindow>
  </MainContainer>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import EditableTable from '@/components/EditableTable/EditableTable.vue'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import PreviewLabels from '@/components/PreviewLabels/PreviewLabels.vue'
import ScreeningStatusLabel from '@/components/ScreeningStatusLabel/ScreeningStatusLabel.vue'
import TicketStatusTags from '@/components/TicketStatusTags/TicketStatusTags.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import CryobeaconNumber from '@/components/CryoBeaconNumber/CryoBeaconNumber.vue'
import ModalWindow from '@/components/ModalWindow/ModalWindow.vue'
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import {
  EMR_SOURCE_ID,
  EXISTING_BEACON_STEP,
  METHOD_IMPORT,
  METHOD_DONATE,
  BIOPSY_RESULT,
  SPECIMEN_NOTES,
  CRYODEVICE,
  METHOD_FREEZE,
  CRYOBEACON,
  LAYOUT_VAULT,
  OPTIONAL
} from '@/constants'
import * as ss from '@/config/session-storage-help'
import { selectEmbryoTypeLabel } from '@/utils'
import { STEP_SELECTED_TICKET } from '@/constants/ticketSteps'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { useTicket, useGetDestination, useValidateDestination } from '@/composables'
import {
  getBreadcrumbs,
  isNumCryoGreaterThanMaxCryo,
  activeBeaconCounterMsg,
  getScreeningStatusBeacons
} from '@/helpers/newTicketHelpers'
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import useGetters from '@/composables/useGetters'
import useActions from '@/composables/useActions'
import useMutations from '@/composables/useMutations'

const router = useRouter()

const modalEdit = ref([SPECIMEN_NOTES, BIOPSY_RESULT, 'freeText'])
const showPrintLabelModal = ref(false)
const isModalOpen = ref(false)
const isLoaderVisible = ref(false)
const isSpaceAvailableInSelectedUnit = ref(false)

// TODO: Consider refactor, activeBeacon and ticket are being overwritten in this view, not the best approach
const { ticket, replaceBeaconInBeaconList, updateTicketBeacons, activeBeacon, saveTicket } =
  useTicket()

const {
  selectedShelf,
  destinationOptions,
  vaultShelfOptions,
  onSelectDestination,
  onSelectShelf,
  selectedDestination,
  resetDestination,
  setDefaultDestination
} = useGetDestination()

const { validateUnitSpace, setUnitSpaceValidationFailed } = useValidateDestination()

// (NOTE: This todo was before composition Migration) TODO: remove this getters and use the ones in ticketModule
// This view is being used for completing EMR tickets, that does not use ticketModule
const {
  currentTicketStep,
  selectedMethod,
  selectedPatient,
  selectedSpecimenType,
  selectedCryoDeviceType,
  donorId,
  shippingSourceId,
  beacons: newTicketBeacons
} = useGetters('newTicketModule')

const { embryoTypes } = useGetters('specimensModule')
const { externalClinics } = useGetters('patientsModule')

const beacons = computed(() => {
  if (newTicketBeacons.value?.length && !isEMRTicket.value) {
    return newTicketBeacons.value
  }
  return [activeBeacon.value]
})
const getActiveBreadcrumbs = computed(() => {
  const currentBreadcrumbs = getBreadcrumbs({
    currentTicketStep: currentTicketStep.value,
    selectedMethod: selectedMethod.value
  })
  return currentBreadcrumbs
})
const computeActiveBeaconCounterMsg = computed(() => {
  return activeBeaconCounterMsg(beacons.value, false)
})
const cryodeviceLabelsData = computed(() => {
  const { cryoDevice } = activeBeacon.value

  if (!cryoDevice) {
    return []
  }

  const { identificationNumber, firstName, lastName } = selectedPatient.value[0]

  const updatedCryoDevices = cryoDevice
    .map((currentCryoDevice) => {
      const cryodeviceTypeId = Number(selectedCryoDeviceType.value.id)
      // TODO: Check if correct
      const procedureDateFrom = dayjs().format('DDMMMYYYY').toUpperCase()
      const embryoTypeLabel = selectEmbryoTypeLabel(
        {
          embryo: {
            ...currentCryoDevice.embryo,
            embryoType: currentCryoDevice.embryo?.embryoType
          }
        },
        embryoTypes.value
      )

      return {
        ...currentCryoDevice,
        cryodeviceTypeId,
        identificationNumber,
        firstName,
        lastName,
        procedureDateFrom,
        embryoTypeLabel
      }
    })
    .sort((a, b) => a.cryodeviceBarcode - b.cryodeviceBarcode)
  return updatedCryoDevices
})

const isDetailEditable = computed(() => {
  // TODO: Define where source is set, currently only found examples of EMR setting source
  // Source is not set during freeze process
  return !isEMRTicket.value
})
const isMethodFreeze = computed(() => {
  return selectedMethod.value === METHOD_FREEZE
})
const isMethodImport = computed(() => {
  return selectedMethod.value === METHOD_IMPORT
})
const buttonText = computed(() => {
  return isMethodImport.value ? 'Next' : 'Print Labels'
})
const buttonIcon = computed(() => {
  return isMethodImport.value ? 'arrow-circle-right' : 'print'
})
const isButtonDisabled = computed(() => {
  const isDisabled = !areMandatoryFieldsValid()
  return isDisabled
})
const cryoDeviceTypeValue = computed(() => {
  return selectedCryoDeviceType.value?.value || ''
})
const isEMRTicket = computed(() => {
  try {
    const { source } = ss.getFieldSessionStorage('process', 'selectedTicketToEdit')
    return source === EMR_SOURCE_ID
  } catch (error) {
    return false
  }
})
const currentDonorId = computed(() => {
  const newDonorId = donorId.value ? donorId.value : getDonorIdFromSelectedTicket()
  return newDonorId
})
const currentClinicName = computed(() => {
  const clinicId = shippingSourceId.value
    ? Number(shippingSourceId.value)
    : getShippingSourceIdFromSelectedTicket()
  const currentClinic = externalClinics.value.find((clinic) => clinic.id === clinicId)
  return currentClinic?.name
})
const defaultDestinationValue = computed(() => {
  const defaultCryorobot = destinationOptions.value.find((cryorobot) => cryorobot.defaultRobot)
  return defaultCryorobot?.value || ''
})
const isVaultRobotSelected = computed(() => {
  const selectedRobot = destinationOptions.value.find(
    (option) => option.value === selectedDestination.value
  )
  return selectedRobot?.robotLayoutTypeId === LAYOUT_VAULT
})

const { displaySpinner, hideSpinner } = useActions('spinnerModule')
const { setBeacons } = useMutations('ticketModule')
const getDonorIdFromSelectedTicket = () => {
  const {
    specimens: [
      {
        sources: [{ donorId }]
      }
    ]
  } = ss.getFieldSessionStorage('process', 'selectedTicketToEdit')
  return donorId
}
const getShippingSourceIdFromSelectedTicket = () => {
  const {
    specimens: [
      {
        sources: [{ shippingSourceId }]
      }
    ]
  } = ss.getFieldSessionStorage('process', 'selectedTicketToEdit')
  return Number(shippingSourceId)
}
const handleBeaconUpdate = async ({ isOutsideLabels = false } = {}) => {
  const maxCryodevicesPerBeacon =
    ss.getFieldSessionStorage('siteProperties')['ticket.cryodevice.max-number']
  const hasSurpassedMaxCryoDevicesAllowed = isNumCryoGreaterThanMaxCryo({
    beacons: beacons.value,
    maxCryodevicesPerBeacon
  })
  if (hasSurpassedMaxCryoDevicesAllowed) {
    handleSelectedBeacons({ isScanBeacon: true })
    return
  }

  if (isMethodImport.value) {
    displaySpinner('Validating')

    try {
      const beaconResponse = await getScreeningStatusBeacons({
        beacons: beacons.value,
        patientId: selectedPatient.value[0].globalPatientNumber
      })

      hideSpinner()

      if (beaconResponse === EXISTING_BEACON_STEP) {
        // AddExistingBeacon screen
        handleSelectedBeacons()
      } else {
        handleSelectedBeacons({ isScanBeacon: true })
      }
      return
    } catch (error) {
      hideSpinner()
    }
  } else if (isOutsideLabels) {
    handleContinue()
  } else {
    showPrintLabelModal.value = true
  }
}
const handleOutsideLabels = () => {
  isModalOpen.value = true
}
const closeModalWindow = () => {
  isModalOpen.value = false
}
const handleConfirmOutsideLabels = () => {
  const updatedActiveBeacon = {
    ...activeBeacon.value,
    externalCryolabel: true,
    cryoDevice: [
      ...activeBeacon.value.cryoDevice.map((cryoDevice) => ({
        ...cryoDevice,
        originalCryodeviceBarcode: cryoDevice.cryodeviceBarcode,
        metadata: {
          ...(cryoDevice.metadata || {}),
          externalCryolabel: true
        }
      }))
    ]
  }

  activeBeacon.value = updatedActiveBeacon
  const newBeacons = replaceBeaconInBeaconList(beacons.value, activeBeacon.value)
  if (ticket.value.ticketId) {
    ticket.value = updateTicketBeacons(ticket.value, newBeacons)
    saveTicket(ticket.value)
  }
  isModalOpen.value = false
  handleBeaconUpdate({ isOutsideLabels: true })
}
const resetLabels = () => {
  if (!activeBeacon.value) {
    return
  }
  let updatedActiveBeacon = {
    ...activeBeacon.value,
    selectedShelf: OPTIONAL,
    cryoDevice: activeBeacon.value.cryoDevice.map((cryoDevice) => ({
      ...cryoDevice,
      cryodeviceBarcodeValidation: undefined
    }))
  }
  if (updatedActiveBeacon.externalCryolabel) {
    updatedActiveBeacon = {
      ...updatedActiveBeacon,
      externalCryolabel: undefined,
      cryoDevice: updatedActiveBeacon.cryoDevice.map((cryoDevice) => ({
        ...cryoDevice,
        cryodeviceBarcode: cryoDevice.originalCryodeviceBarcode,
        originalCryodeviceBarcode: undefined,
        metadata: {
          ...(cryoDevice.metadata || {}),
          externalCryolabel: undefined // use undefined instead of false so axios can remove this field on the payload
        }
      }))
    }
  }

  activeBeacon.value = updatedActiveBeacon
  const newBeacons = replaceBeaconInBeaconList(beacons.value, activeBeacon.value)
  if (ticket.value.ticketId) {
    ticket.value = updateTicketBeacons(ticket.value, newBeacons)
    saveTicket(ticket.value)
  }
}
const handleContinue = () => {
  handleSelectedBeacons()
}

const handleSelectedBeacons = async ({ isScanBeacon = false } = {}) => {
  const currentBeacons = beacons.value.map((beacon) => {
    const { beaconId } = beacon

    if (beaconId === activeBeacon.value.beaconId) {
      return { ...activeBeacon.value, selectedDestination: selectedDestination.value }
    }

    return beacon
  })

  setBeacons(currentBeacons)
  const nextStepPath = await executeNextTicketStep({
    selectedMethod: selectedMethod.value,
    currentTicketStep: currentTicketStep.value,
    beacons: currentBeacons,
    isScanBeacon
  })
  router.push({ name: nextStepPath })
}
const handleClickBack = () => {
  let previousStepPath
  switch (isEMRTicket.value) {
    case true:
      previousStepPath = executePreviousTicketStep({
        selectedMethod: selectedMethod.value,
        currentTicketStep: currentTicketStep.value,
        fromRouteName: STEP_SELECTED_TICKET
      })
      break
    default: {
      previousStepPath = executePreviousTicketStep({
        selectedMethod: selectedMethod.value,
        currentTicketStep: currentTicketStep.value
      })
      break
    }
  }

  router.replace({ name: previousStepPath })
}
const handleSpecimenChange = (beaconSpecimens) => {
  const updatedActiveBeacon = {
    ...activeBeacon.value,
    cryoDevice: [...beaconSpecimens]
  }
  activeBeacon.value = updatedActiveBeacon
  const newBeacons = replaceBeaconInBeaconList(beacons.value, activeBeacon.value)
  if (ticket.value.ticketId) {
    ticket.value = updateTicketBeacons(ticket.value, newBeacons)
    saveTicket(ticket.value)
  }
}
const handleClosePrintModal = () => {
  showPrintLabelModal.value = false
}

const hasCryoDates = () => {
  const activeBeaconCryoDevices = activeBeacon.value.cryoDevice
  const hasCryoDates = !!activeBeaconCryoDevices?.every((cryoDevice) =>
    Boolean(cryoDevice.cryoDate)
  )
  return hasCryoDates
}
const areMandatoryFieldsValid = () => {
  let areFieldsValid = true

  if (isMethodImport.value) {
    areFieldsValid = hasCryoDates()
  }

  return areFieldsValid
}
const onChangeShelf = (value) => {
  onSelectShelf(value)
  activeBeacon.value = {
    ...activeBeacon.value,
    selectedShelf: value
  }
}
const onChangeDestination = async (value) => {
  onSelectDestination(value)
  const updatedActiveBeacon = {
    ...activeBeacon.value,
    selectedDestination: selectedDestination.value
  }
  activeBeacon.value = updatedActiveBeacon
  if (value) {
    const validationPayload = {
      screeningStatus: cryodeviceLabelsData.value[0].screeningStatus,
      containerId: value
    }
    isLoaderVisible.value = true
    const hasUnitAvailableSpace = await validateUnitSpace(validationPayload)
    // TODO: Check if this is correct, seems to be not used anywhere
    isSpaceAvailableInSelectedUnit.value = hasUnitAvailableSpace
    isLoaderVisible.value = false
  } else {
    // TODO: Check if correct, on options api was trying to rewrite a computed property
    // isButtonDisabled.value = true
    setUnitSpaceValidationFailed(false)
  }
}

watch(destinationOptions, () => {
  // Reset Destination after destinationOptions are updated
  resetDestination()
  setDefaultDestination()
})
onMounted(async () => {
  activeBeacon.value = beacons.value.find((beacon) => beacon.active)
  if (selectedMethod.value === METHOD_DONATE) {
    const nextStepPath = await executeNextTicketStep({
      selectedMethod: selectedMethod.value,
      currentTicketStep: currentTicketStep.value
    })

    router.push({ name: nextStepPath })
  }
  resetLabels()
})
</script>
