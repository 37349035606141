<template>
  <MainContainer data-test="scan-beacon" :gridSlots="!showBreadcrumbs ? '3' : '4'">
    <top-header @backStep="handleClickBack">
      <p v-if="isReplaceCryoBeacon">Replace {{ CRYOBEACON }}</p>
      <HeaderInfo v-else />
    </top-header>
    <ActionBar data-test="scan-beacon__action-bar" colsDistribution="9/3">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left">
          Please register the {{ CRYOBEACON }} by placing it in the Registration Tray
        </dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          v-if="isReplaceCryoBeacon"
          btnType="button"
          :isLoading="isLoading"
          data-test="scan-beacon__replace-cryo-beacon-button"
          btnText="Update Ticket"
          btnStyle="primary"
          :is-disabled="!beaconBarcodeNumber"
          @click="handleReplaceCryoBeacon"
        />
        <!-- Edit Donation to Patient ticket -->
        <!-- We did not remove this flow from the mixin since it is about editing -->
        <button-dynamic
          v-else-if="isDonationToPatientTicket"
          btnType="button"
          :isLoading="isLoading"
          data-test="scan-beacon__update-ticket-button"
          btnText="Update Ticket"
          btnStyle="primary"
          :is-disabled="!beaconBarcodeNumber"
          @click="handleDonateTicketUpdate"
        />
        <!-- Submit ticket Update -->
        <!-- We did not remove this flow from the mixin since it is about editing -->
        <button-dynamic
          v-else-if="isEditingTicket() && isScanningLastBeacon"
          btnType="button"
          :isLoading="isLoading"
          data-test="scan-beacon__update-ticket-button"
          btnText="Update Ticket"
          btnStyle="primary"
          :is-disabled="!beaconBarcodeNumber"
          @click="ticketUpdateHandle"
        />
        <!-- Next to final ticket Preview -->
        <button-dynamic
          v-else-if="isScanningLastBeacon"
          data-test="scan-beacon__next-button"
          btnType="button"
          btnText="Next"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="arrow-circle-right"
          :isDisabled="!isBeaconValid"
          @click="handleBeaconsComplete"
        />
        <!-- Go to Next Beacon -->
        <button-dynamic
          v-else
          data-test="scan-beacon__next-button"
          btnType="button"
          :btnText="`Next ${CRYOBEACON}`"
          btnStyle="primary"
          :isDisabled="!isBeaconValid"
          @click="onClickNextBeacon"
        />
      </template>
    </ActionBar>
    <BreadCrumbs :items="getActiveBreadcrumbs" v-if="showBreadcrumbs" />
    <NewCryoBeacon @isValidBeaconHandler="isValidBeaconHandler" isReplaceCryoBeacon />
  </MainContainer>
  <ModalGeneric
    v-if="isReplaceCryoBeaconModalOpen"
    data-test="replace-cryobeacon-modal"
    :title="`Replace ${CRYOBEACON}`"
    :message="`Once the new ${CRYOBEACON} is submerged in liquid nitrogen, please move the contents of ${CRYOBEACON} ${oldCryoBeacon} to ${CRYOBEACON} ${newCryoBeacon}.`"
    confirm-label="Ok"
    modal-width="w-5/12"
    :title-classes="['text-2xl']"
    :message-classes="['text-lg pt-4']"
    confirmButtonStyle="secondary"
    hideCancel
    @confirm="handleCryoBeaconReplace"
    @cancel="handleCryoBeaconReplaceCancel"
  />
  <loading-ui
    v-if="isProcessingCryoBeaconReplace"
    modal
    :message="`Updating Ticket ${CRYOBEACON}`"
  />
</template>

<script setup lang="ts">
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ModalGeneric from '@/components/ModalGeneric/ModalGeneric.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import NewCryoBeacon from '@/components/NewCryoBeacon/NewCryoBeacon.vue'
import {
  CRYOBEACON,
  EXISTING_BEACON_TICKET_SUCCESS_MESSAGE,
  EXISTING_BEACON_STEP,
  METHOD_FREEZE,
  METHOD_IMPORT,
  PROCEDURE_TYPE_DONATION_TO_PATIENT
} from '@/constants'
import toast from '@/config/toast'
import * as ss from '@/config/session-storage-help'
import { useTicket } from '@/composables/useTicket'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { getBreadcrumbs, getScreeningStatusBeacons } from '@/helpers/newTicketHelpers'
import { processNextBeacon } from '@/helpers/manageBeacon'
import { updateDonationToPatientBeaconBarcode, handleTicketUpdate } from '@/helpers/updateTicket'
import { STEP_LABEL_SCAN } from '@/constants/ticketSteps'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import { computed, inject, onMounted, ref } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import useActions from '@/composables/useActions'
import useMutations from '@/composables/useMutations'
import useGetters from '@/composables/useGetters'
import { IncompleteTicket } from '@/types/ticket'

const fromRoute = inject<{
  name: string
}>('fromRoute')

const route = useRoute()
const router = useRouter()

const { ticket, beacons, updateTicketBeacons, saveTicket, isEditingTicket } = useTicket()

const fromRouteName = ref<string>()
const isLoading = ref(false)
const isBeaconValid = ref(false)
const isReplaceCryoBeacon = ref(false)
const selectedTicket = ref<any>(null)
const oldCryoBeacon = ref('')
const newCryoBeacon = ref('')
const isReplaceCryoBeaconModalOpen = ref(false)
const isProcessingCryoBeaconReplace = ref(false)

const { beaconBarcodeNumber } = useGetters('beaconsModule')
const { loggedUserInfo, appBaseConfig } = useGetters('authModule')
const { currentTicketStep, selectedMethod, selectedPatient } = useGetters('newTicketModule')
const { selectedDestination } = useGetters('ticketsModule')
const { selectedTickets } = useGetters('selectedTicketsModule')
const getActiveBreadcrumbs = computed(() => {
  const currentBreadcrumbs = getBreadcrumbs({
    currentTicketStep: currentTicketStep.value,
    selectedMethod: selectedMethod.value
  })
  return currentBreadcrumbs
})
const activeSlide = computed(() => {
  return ss.getFieldSessionStorage('process', 'activeSlideSelectedTickets') || 1
})
const isScanningLastBeacon = computed(() => {
  const unScannedBeacons = beacons.value.filter((beacon: any) => !beacon.done)
  return unScannedBeacons.length === 1
})

const { setSingleBeaconReaderReads, setBeaconBarcode } = useActions('beaconsModule')
const { displaySpinner, hideSpinner } = useActions('spinnerModule')
const { updateTickets } = useActions('selectedTicketsModule')
const { updateTicketCryoBeacon } = useActions('ticketsModule')
const { clearScannedBeaconBarcodeNumbers, addScannedBeaconBarcodeNumber } =
  useMutations('beaconsModule')

const { setBeacons } = useMutations('ticketModule')
const ticketUpdateHandle = async () => {
  isLoading.value = true
  displaySpinner('Updating Ticket')

  const unitId = selectedDestination.value
  if (unitId) {
    ticket.value = { ...ticket.value, unitId }
  }

  try {
    await handleTicketUpdate({
      router,
      ticket: ticket.value as IncompleteTicket,
      collectionProtocolId: appBaseConfig.value.collectionProtocolId,
      beaconBarcodeNumber: beaconBarcodeNumber.value,
      loggedUserId: loggedUserInfo.value.userId
    })
  } catch (err: any) {
    toast.error({ title: err.message })
  } finally {
    isLoading.value = false
    ss.removeFieldSessionStorage('selectedDestination')
    hideSpinner()
  }
}
const handleCryoBeaconReplaceCancel = () => {
  isReplaceCryoBeaconModalOpen.value = false
}
const handleDonateTicketUpdate = async () => {
  isLoading.value = true
  const [donateTicketData] = selectedTickets.value
  const donationToPatientTicket = updateDonationToPatientBeaconBarcode(
    donateTicketData,
    beaconBarcodeNumber.value
  )
  const { ticketId } = donationToPatientTicket
  try {
    await updateTickets({ tickets: [donationToPatientTicket], ticketId })
    setBeaconBarcode(null)
    router.push({
      path: '/selected-tickets',
      query: {
        activeSlide: activeSlide.value
      }
    })
    toast.success(EXISTING_BEACON_TICKET_SUCCESS_MESSAGE)
    isLoading.value = false
  } catch (err: any) {
    toast.error({ title: err.message })
  } finally {
    isLoading.value = false
  }
}
const onClickNextBeacon = async () => {
  setSingleBeaconReaderReads(beaconBarcodeNumber.value)
  // TODO: check what is this used for in the ticket creation flow, should it be part of the ticketModule?
  addScannedBeaconBarcodeNumber(beaconBarcodeNumber.value)
  // TODO: check what is this used for in the ticket creation flow, should it be part of the ticketModule?
  ss.setFieldSessionStorage('lastScannedBeacon', beaconBarcodeNumber.value)
  beacons.value = processNextBeacon(beacons.value) as any
  ticket.value = updateTicketBeacons(ticket.value, beacons.value)
  saveTicket(ticket.value)

  setBeacons(beacons.value)

  const nextStepPath = await executeNextTicketStep({
    selectedMethod: selectedMethod.value,
    currentTicketStep: currentTicketStep.value,
    beaconBarcodeNumber: beaconBarcodeNumber.value,
    beacons: beacons.value,
    hasNextBeacon: true
  })
  router.push({ name: nextStepPath })
}
const isValidBeaconHandler = (newIsBeaconValid) => {
  isBeaconValid.value = newIsBeaconValid
}
const handleBeaconsComplete = async () => {
  const nextStepPath = await executeNextTicketStep({
    selectedMethod: selectedMethod.value,
    currentTicketStep: currentTicketStep.value,
    beaconBarcodeNumber: beaconBarcodeNumber.value
  })

  router.push({ name: nextStepPath })
}
const handleClickBack = async () => {
  if (isReplaceCryoBeacon.value) {
    const isRelocationTicket = ss.getFieldSessionStorage('isRelocationTicket')
    ss.removeFieldSessionStorage('isRelocationTicket')
    router.push({
      path: isRelocationTicket ? '/biorepository/relocation-ticket' : '/selected-tickets',
      query: {
        activeSlide: activeSlide.value
      }
    })
    return
  }
  let isExistingBeacon = false
  if ([METHOD_FREEZE, METHOD_IMPORT].includes(selectedMethod.value)) {
    displaySpinner('Validating Patient Inventory')
    try {
      const beaconResponse = await getScreeningStatusBeacons({
        beacons: beacons.value,
        patientId: selectedPatient.value[0].globalPatientNumber
      })

      hideSpinner()
      isExistingBeacon = beaconResponse === EXISTING_BEACON_STEP
    } catch (error) {
      hideSpinner()
    }
  }
  const previousStepPath = executePreviousTicketStep({
    selectedMethod: selectedMethod.value,
    currentTicketStep: currentTicketStep.value,
    isExistingBeacon
  })
  router.replace({ name: previousStepPath })
}
const showBreadcrumbs = computed(() => {
  // TODO: There was an error on the template logic that always hid the breadcrumbs on this screen
  // but no issue was reported, need to check with the design team is breadcrumbs should show or not in this screen
  // if they should, then we just need to enable the following line and adjust if necessary
  // return !isDonationToPatientTicket.value && !isReplaceCryoBeacon.value
  return false
})
const isDonationToPatientTicket = computed(() => {
  if (!selectedTickets.value.length) {
    return false
  }
  const [{ procedureType }] = selectedTickets.value
  return procedureType === PROCEDURE_TYPE_DONATION_TO_PATIENT
})
const handleReplaceCryoBeacon = () => {
  selectedTicket.value = selectedTickets.value[activeSlide.value - 1]
  oldCryoBeacon.value = selectedTicket.value!.beaconBarcode
  newCryoBeacon.value = beaconBarcodeNumber.value
  isReplaceCryoBeaconModalOpen.value = true
}
const handleCryoBeaconReplace = async () => {
  isReplaceCryoBeaconModalOpen.value = false
  isProcessingCryoBeaconReplace.value = true
  const { ticketId } = selectedTicket.value
  try {
    await updateTicketCryoBeacon({ ticketId, beaconId: beaconBarcodeNumber.value })
    toast.success(EXISTING_BEACON_TICKET_SUCCESS_MESSAGE)
    const isRelocationTicket = ss.getFieldSessionStorage('isRelocationTicket')
    ss.removeFieldSessionStorage('isRelocationTicket')
    router.push({
      path:
        isRelocationTicket === 'true' ? '/biorepository/relocation-ticket' : '/selected-tickets',
      query: { activeSlide: activeSlide.value }
    })
  } catch (err: any) {
    toast.error({ title: err.message })
  } finally {
    isProcessingCryoBeaconReplace.value = false
  }
}

onMounted(() => {
  isReplaceCryoBeacon.value = !!route.query.isReplaceCryoBeacon
  beacons.value = ss.getFieldSessionStorage('newTicket', 'beacons')
  fromRouteName.value = fromRoute?.name
})

onBeforeRouteLeave((to) => {
  if (to.name !== STEP_LABEL_SCAN) {
    clearScannedBeaconBarcodeNumbers()
  }
})
</script>

<style lang="scss">
.single-beacon-reader-img {
  max-height: 500px;
}
</style>
