import {
  ROBOT_TRANSFER_IN,
  ROBOT_TRANSFER_OUT,
  ROBOT_TRANSFER_DONE,
  RACK,
  SHELF,
  POSITION,
  ROBOT_TRANSFER_READY,
  ROBOT_TRANSFER_NOT_READY,
  DESTINATION,
  SOURCE,
  NON_TMRW_LOCATION,
  ROBOT_LAYOUT_TYPES,
  TEMPORARY,
  LAYOUT_B3C,
  LAYOUT_CRS,
  LAYOUT_WAVE,
  LAYOUT_VAULT,
  CRYOGRID
} from '@/constants'
import inCryorobot from '@/assets/in-cryorobot.svg'
import outCryorobot from '@/assets/out-cryorobot.svg'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import store from '@/store'
import { Container } from '@/types'
import { getRobotLocationApi } from '@/services/tickets'

dayjs.extend(utc)

export type RobotLocation = {
  cassetteId: number
  column: string
  rack: string
  robotId: string
  robotNumber: number
  row: string
  shelf: string
  transferStatus: string
  transferType: string
  transferTypeName: string
  robotLayoutTypeId: number
  type: string
  siteName: string
  locationDetails: string
  completedAt: string
}

export type UnitTypes = {
  isB3CUnit: boolean
  isCRSUnit: boolean
  isWaveUnit: boolean
  isVaultUnit: boolean
}

export type ProcessedLocation = {
  location: string
  robotNumber: string
  imageSrc: string | null
  transferStatus: string
  transferType: string
  locationType: string
  transferTypeName: string | null
  isTemporary: boolean
  robotTypeName: string
  robotName: string
  robotLayoutTypeId: number
  siteName: string
  locationDetails: string
  type: string
  completedAtDate: string
  completedAtTime: string
  robotId: string
  row: string
  column: string
  cassetteId: number
  id?: string
}

export type CryoBeaconLocation = {
  location: string
  cryoRobotName: string
}

enum VAULT_SHELF {
  top = 'Top',
  middle = 'Middle',
  bottom = 'Bottom'
}

const vaultShelfTypes = {
  T: VAULT_SHELF.top,
  M: VAULT_SHELF.middle,
  B: VAULT_SHELF.bottom
}
export const getShelfLabel = (shelfKey: string): VAULT_SHELF => vaultShelfTypes[shelfKey]

export const isDone = (status: string): boolean => status === ROBOT_TRANSFER_DONE
export const isReady = (status: string): boolean => status === ROBOT_TRANSFER_READY
export const isNotReady = (status: string): boolean => status === ROBOT_TRANSFER_NOT_READY

const getTransferImageSrc = (transferType: string, reversed: boolean = false): string | null => {
  switch (transferType) {
    case ROBOT_TRANSFER_OUT:
      return reversed ? inCryorobot : outCryorobot

    case ROBOT_TRANSFER_IN:
      return reversed ? outCryorobot : inCryorobot

    default:
      return null
  }
}

const getTransferTypeName = (transferType: string, reversed: boolean = false): string | null => {
  switch (transferType) {
    case ROBOT_TRANSFER_OUT:
      return reversed ? 'Retrieve' : 'Store'

    case ROBOT_TRANSFER_IN:
      return reversed ? 'Store' : 'Retrieve'

    default:
      return null
  }
}

const determineUnitType = (robotLayoutTypeId: number): UnitTypes => {
  const isB3CUnit = robotLayoutTypeId === LAYOUT_B3C
  const isCRSUnit = robotLayoutTypeId === LAYOUT_CRS
  const isWaveUnit = robotLayoutTypeId === LAYOUT_WAVE
  const isVaultUnit = robotLayoutTypeId === LAYOUT_VAULT
  return { isB3CUnit, isCRSUnit, isWaveUnit, isVaultUnit }
}

const isVaultByUnitId = (unitId: number): boolean => {
  if (!unitId) return false
  const containers = store.getters['siteModule/allContainers']
  const robot = containers.find((container) => Number(container.containerId) === Number(unitId))
  return robot?.robotLayoutTypeId === LAYOUT_VAULT
}

const composeCryoBeaconLocation = (location: RobotLocation): string => {
  const { rack, shelf, row, column, robotLayoutTypeId, robotId } = location
  const { isB3CUnit, isCRSUnit, isWaveUnit } = determineUnitType(robotLayoutTypeId)
  const containers = store.getters['siteModule/allContainers']
  const container = containers.find((container) => container.containerId === Number(robotId))
  if (!container) {
    return ''
  }
  const robotName = container.robotName

  if (isB3CUnit)
    return `${robotName} - ${RACK} ${rack} - ${SHELF} ${shelf} - ${POSITION} ${row}${column}`
  if (isCRSUnit) return `${robotName} - ${POSITION} ${row}${column}`
  if (isWaveUnit) return robotName
  return ''
}

const composeCryoBeaconLocationDetails = (location: RobotLocation): string => {
  const { rack, shelf, row, column, robotLayoutTypeId, locationDetails } = location
  const { isB3CUnit, isCRSUnit, isWaveUnit } = determineUnitType(robotLayoutTypeId)

  if (isB3CUnit) return `${RACK} ${rack} - ${SHELF} ${shelf} - ${POSITION} ${row}${column}`
  if (isCRSUnit) return `${POSITION} ${row}${column}`
  if (isWaveUnit) return locationDetails
  return ''
}

const getRobotLocationDetailsFromApi = async (location: RobotLocation) => {
  const { row, column, cassetteId, robotId } = location
  const resp = await getRobotLocationApi({
    cassetteId,
    rowId: row,
    columnId: column,
    unitId: robotId
  })

  return `${getShelfLabel(resp.data.shelf)} ${SHELF} - ${CRYOGRID} ${
    resp.data.cryogrid
  } - ${POSITION} ${resp.data.row}${resp.data.column}`
}

const composeRobotNumber = (location: RobotLocation): string => {
  const { robotNumber, robotLayoutTypeId } = location
  const isWaveUnit = robotLayoutTypeId === LAYOUT_WAVE

  return isWaveUnit
    ? NON_TMRW_LOCATION
    : // @ts-ignore
      robotNumber === TEMPORARY
      ? TEMPORARY
      : `${robotNumber}`
}

const checkIfTemporary = (location: RobotLocation): boolean => {
  const { robotLayoutTypeId, robotNumber } = location
  const isWaveUnit = robotLayoutTypeId === LAYOUT_WAVE
  // @ts-ignore
  const isNonTMRWLocation = robotNumber === NON_TMRW_LOCATION
  return isNonTMRWLocation || isWaveUnit
}

const getRobotName = (location: RobotLocation): string => {
  try {
    const { robotId } = location
    const containers = store.getters['siteModule/allContainers']
    const container = containers.find(
      (container) => Number(container.containerId) === Number(robotId)
    )
    return container?.robotName || ''
  } catch (error) {
    console.error('Error getting all containers:', error)
    return ''
  }
}

const composeInOutLocation = (
  location: RobotLocation,
  reversed: boolean = false
): ProcessedLocation => {
  const {
    transferStatus,
    transferType,
    robotLayoutTypeId,
    siteName,
    completedAt,
    type: locationType,
    robotId,
    row,
    column,
    cassetteId
  } = location
  const hasRobotName = ROBOT_LAYOUT_TYPES.filter((layout) => layout.id === robotLayoutTypeId)
  const robotTypeName = hasRobotName.length ? hasRobotName[0].value : ''

  const locationObject: ProcessedLocation = {
    location: composeCryoBeaconLocation(location),
    robotNumber: composeRobotNumber(location),
    imageSrc: getTransferImageSrc(transferType, reversed) || '',
    row,
    column,
    cassetteId,
    transferStatus,
    transferType,
    locationType,
    transferTypeName: getTransferTypeName(transferType, true),
    isTemporary: checkIfTemporary(location),
    robotTypeName,
    robotName: getRobotName(location),
    type: locationType,
    robotLayoutTypeId,
    siteName,
    locationDetails: composeCryoBeaconLocationDetails(location),
    completedAtDate: completedAt ? dayjs(completedAt).format('DDMMMYYYY') : '--',
    completedAtTime: completedAt ? dayjs(completedAt).format('hh:mmA') : '--',
    robotId
  }

  return locationObject
}

const getDestination = (locations: RobotLocation[]): RobotLocation => {
  return locations.find((location) => location.type === DESTINATION) as RobotLocation
}

const orderLocations = (locations: Array<RobotLocation | ProcessedLocation>) => {
  if (locations.length > 1) {
    const source = locations.find((loc) => loc.type === SOURCE)
    const destination = locations.find((loc) => loc.type === DESTINATION)
    return [source!, destination!]
  }
  return locations
}

const getCurrentCryoBeaconLocation = (locations: RobotLocation[]): ProcessedLocation => {
  let currentCryoBeaconLocation: ProcessedLocation = {} as ProcessedLocation

  // We need to order the locations in the array
  // to source first and then destination
  const sortedLocations = orderLocations(locations)

  sortedLocations.every((location) => {
    const { transferStatus } = location

    // If location is ready parse data and return
    // If location is NOT ready parse data and return
    if (isReady(transferStatus) || isNotReady(transferStatus)) {
      currentCryoBeaconLocation = composeInOutLocation(location, true)
      return false
    }

    // If location is done parse loop until the last location and return
    if (isDone(transferStatus)) {
      currentCryoBeaconLocation = composeInOutLocation(location)
    }

    return true
  })

  return currentCryoBeaconLocation
}

const getCryoBeaconLocationByType = (
  locations: RobotLocation[],
  locationType: string
): ProcessedLocation => {
  let currentCryoBeaconLocation: ProcessedLocation = {} as ProcessedLocation

  // We need to order the locations in the array
  // to source first and then destination
  const sortedLocations = locations.filter((location) => {
    return location.type === locationType
  })

  sortedLocations.every((location) => {
    const { transferStatus } = location

    // If location is ready parse data and return
    // If location is NOT ready parse data and return
    if (isReady(transferStatus) || isNotReady(transferStatus)) {
      currentCryoBeaconLocation = composeInOutLocation(location, true)
      return false
    }

    // If location is done parse loop until the last location and return
    if (isDone(transferStatus)) {
      currentCryoBeaconLocation = composeInOutLocation(location)
    }

    return true
  })

  return currentCryoBeaconLocation
}

const getLocationDetailForVault = async ({ unitId, extParentId, posY, posX }) => {
  let locationDetail = ''
  try {
    locationDetail = await getRobotLocationDetailsFromApi({
      robotId: String(unitId),
      cassetteId: extParentId,
      row: posY,
      column: posX
    } as RobotLocation)
  } catch (error) {
    locationDetail = ''
  }

  return locationDetail
}

const getCryoBeaconLocation = async (cryoBeacon: Container): Promise<CryoBeaconLocation> => {
  const {
    robotLayoutTypeId,
    containerLocation: { rack, shelf, posX, posY, locationDetails, unitId, extParentId }
  } = cryoBeacon
  let location = ''

  switch (robotLayoutTypeId) {
    case LAYOUT_B3C:
      location = `(${RACK} ${rack} - ${SHELF} ${shelf} - ${POSITION} ${posY}${posX})`
      break
    case LAYOUT_CRS:
      location = `(${POSITION} ${posX}${posY})`
      break
    case LAYOUT_WAVE:
      location = `(${locationDetails})`
      break
    case LAYOUT_VAULT:
      location = `(${
        (await getLocationDetailForVault({ unitId, extParentId, posY, posX })) as string
      })`
      break

    default:
      location = ''
      break
  }
  const cryoBeaconLocation = {
    location,
    cryoRobotName: getRobotName({ robotId: `${unitId}` } as RobotLocation)
  }
  return cryoBeaconLocation
}

export {
  getCurrentCryoBeaconLocation,
  getCryoBeaconLocationByType,
  orderLocations,
  determineUnitType,
  isVaultByUnitId,
  getRobotLocationDetailsFromApi,
  getCryoBeaconLocation,
  getRobotName,
  getDestination
}
