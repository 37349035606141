<template>
  <MainContainer data-test="biorepository-flight-board" gridSlots="3">
    <top-header :back-btn="false"></top-header>
    <ActionBar data-test="customize-beacons__action-bar" colsDistribution="6/6" setLeftSideToBottom>
      <template v-slot:left-side>
        <Tabs :options="options" :active="filterBiorepo" @changeValue="handleTabChange" />
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btn-data-test="print-board-button"
          btnType="button"
          btnText="Print Board"
          btnStyle="transparent"
          isBgTransparent
          isTextUnderlined
          showIcon
          :addClasses="['print-tickets-btn']"
          fontAwesomeIconClass="print"
          @click="composeFlightBoardPDF"
        />
        <button-dynamic
          btnType="button"
          btnText="View Selected"
          btnStyle="secondary"
          addMarginLeft
          :isDisabled="!hasSelectedTickets"
          @click="viewSelectedTickets"
        />
        <button-dynamic
          btn-data-test="new-batch-ticket-button"
          btnType="button"
          btnText="New Batch Ticket"
          btnStyle="secondary"
          addMarginLeft
          @click="handleNewBatchTicket"
        />
        <button-dynamic
          btnType="button"
          btnText="New Relocation Ticket"
          btnStyle="primary"
          addMarginLeft
          showIcon
          fontAwesomeIconClass="plus-circle"
          @click="handleMoveLocationTicket"
        />
      </template>
    </ActionBar>
    <!-- @vue-ignore -->
    <ScrollTableComponent
      :minHeight="hasTickets ? 'h-full' : 'h-56'"
      v-model="tableData"
      selectionMode="checkbox"
      :headers="relocationFlightboardHeaders"
      :isRelocationView="true"
      :headerInfo="headerInfo"
      tableWrapperClasses="rounded-b-md overflow-hidden"
      :hasTabs="true"
    />
  </MainContainer>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import Tabs from '@/components/Tabs/Tabs.vue'

import * as ss from '@/config/session-storage-help'
import {
  TICKET_SCHEDULED,
  TICKET_IN_PROGRESS,
  TICKET_COMPLETE,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  TICKET_OPEN_CAPITALS,
  TICKET_COMPLETE_CAPITALS,
  TICKET_COMPLETED,
  TICKET_OPEN,
  TO_AND_FROM,
  TICKET_INITIAL
} from '@/constants'
import { executeNextTicketStep, resetTicketProcess } from '@/helpers/manageTicket'

import { STEP_INITIAL } from '@/constants/ticketSteps'

import { ROUTE_MOVE_TICKET } from '@/constants/moveLocationTicketSteps'
import {
  getTotalCryoShipperTickets,
  getTotalRelocationTickets,
  getTotalBatchTickets
} from '@/helpers/ticketState'
import { computed, onMounted, ref, watch } from 'vue'
import useGetters from '@/composables/useGetters'
import useActions from '@/composables/useActions'
import { useRouter } from 'vue-router'
import { FligthBoardToPdf } from '@/helpers/printPDF/flightBoardToPdf'
import { relocationFlightboardHeaders } from '@/constants/table-headers/relocation-headers'

dayjs.extend(utc)

const router = useRouter()

const tableData = ref<any>({
  sort: {
    orderBy: 'procedureTime',
    direction: 'desc'
  },
  options: []
})
const options = ref([
  {
    label: TICKET_OPEN,
    value: TICKET_OPEN_CAPITALS
  },
  {
    label: TICKET_COMPLETED,
    value: TICKET_COMPLETE_CAPITALS
  }
])

const { moveLocationTickets, filterBiorepo } = useGetters('ticketsModule')
const { selectedTickets } = useGetters('selectedTicketsModule')

const hasTickets = computed(() => {
  return !!tableData.value.options.length
})
const hasSelectedTickets = computed(() => {
  return !!selectedTickets.value.length
})

const headerInfo = computed(() => {
  const items = tableData.value.options.flat()
  const totalCryoShipperTickets = getTotalCryoShipperTickets(items)
  const totalRelocationTickets = getTotalRelocationTickets(items) + getTotalBatchTickets(items)
  return `<span class="font-normal">Displaying </span>${totalRelocationTickets} Relocation Tickets, ${totalCryoShipperTickets} CryoShipper Tickets`
})

const { fetchMoveLocationTickets, setFilterBiorepo } = useActions('ticketsModule')
const { displaySpinner, hideSpinner } = useActions('spinnerModule')
const { setSelectedTickets, setSelectedViewMode, setViewTicket } =
  useActions('selectedTicketsModule')
const { setTicketMode } = useActions('biorepoTicketModule')

const { getAllSites } = useActions('siteModule')

const viewSelectedTickets = () => {
  router.push({ name: ROUTE_MOVE_TICKET })
}
const handleNewBatchTicket = async () => {
  const nextStepPath = await executeNextTicketStep({
    selectedMethod: PROCEDURE_TYPE_BATCH_UPPERCASE,
    currentTicketStep: STEP_INITIAL
  })

  router.push({ name: nextStepPath })
}
const composeFlightBoardPDF = async () => {
  const printingFlightBoardToPDF = await FligthBoardToPdf(tableData.value.options, 'OPEN')
  return printingFlightBoardToPDF
}

const handleMoveLocationTicket = () => {
  resetTicketProcess()
  router.push({ name: 'BiorepositoryNewTicket' })
}
const groupTicketsByStatus = (tickets) => {
  const readyForMoveTickets: any[] = []
  const readyForOffSiteTickets: any[] = []
  const completeTickets: any[] = []
  const initialTickets: any[] = []

  tickets.forEach((ticket) => {
    switch (ticket.state) {
      case TICKET_SCHEDULED:
        readyForMoveTickets.push(ticket)
        break
      case TICKET_IN_PROGRESS:
        readyForOffSiteTickets.push(ticket)
        break
      case TICKET_COMPLETE:
        completeTickets.push(ticket)
        break
      case TICKET_INITIAL:
        initialTickets.push(ticket)
        break
    }
  })

  const ticketsGrouped = [
    readyForMoveTickets,
    readyForOffSiteTickets,
    completeTickets,
    initialTickets
  ]

  if (!ticketsGrouped.flat().length) return []

  return ticketsGrouped.flat()
}
const handleTabChange = async (option) => {
  setFilterBiorepo(option.value)
  displaySpinner('Loading Tickets')
  await fetchMoveLocationTickets({
    ticketState: option.value,
    siteMode: TO_AND_FROM
  })
  hideSpinner()
}

const resetViewTicketType = () => {
  const ticketData = {
    ticketType: 'general',
    ticketId: ''
  }
  setViewTicket(ticketData)
}

onMounted(async () => {
  try {
    setFilterBiorepo(TICKET_OPEN_CAPITALS)
    setSelectedTickets([])
    ss.setFieldSessionStorage('isBiorepositoryRoute', true, 'process')
    ss.removeFieldSessionStorage('process', 'activeSlideSelectedTickets')
    setTicketMode('none')
    resetViewTicketType()
    setSelectedViewMode('normal')
    getAllSites()
    displaySpinner()
    await fetchMoveLocationTickets({
      ticketState: filterBiorepo.value,
      siteMode: TO_AND_FROM
    })
  } finally {
    hideSpinner()
  }
})

watch(moveLocationTickets, (nextTickets) => {
  tableData.value.options = groupTicketsByStatus(nextTickets)
})
watch(tableData, (nextTableData, prevTableData) => {
  if (nextTableData.options !== prevTableData.options) {
    const newSelectedTickets = nextTableData.options.flat().filter((option) => option.selected)
    setSelectedTickets(newSelectedTickets)
  }
})
</script>
