export default {
  setFilter(state, filter) {
    state.filter = filter
  },
  setFilterBiorepo(state, filter) {
    state.filterBiorepo = filter
  },
  fetchTickets(state, tickets) {
    state.tickets = tickets
  },
  fetchMoveLocationTickets(state, moveLocationTickets) {
    state.moveLocationTickets = moveLocationTickets
  },
  success(state) {
    state.status = 'completed'
  },
  error(state, error) {
    state.status = 'error'
    state.error = error
  },
  loading(state) {
    state.status = 'loading'
  },
  setDestinationOptions(state, payload) {
    state.destinationOptions = payload
  },
  setSelectedDestination(state, payload) {
    state.selectedDestination = payload
  },
  setSelectedShelf(state, shelf) {
    state.selectedShelf = shelf
  }
}
