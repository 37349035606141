<template>
  <div class="border-t border-tmrw-gray border-solid custom-width">
    <div v-if="hasTickets.top">
      <div
        class="py-3 px-4 text-sm border-b border-tmrw-blue border-dotted text-tmrw-blue font-semibold"
      >
        <span class="inline-block w-[250px] mr-64">{{ headerInfo.top.title }}</span>
        <span>{{ headerInfo.top.scroll }}</span>
      </div>
      <div class="overflow-x-auto table-width">
        <!-- @vue-ignore -->
        <ScrollTableComponent
          v-if="hasTickets.top"
          v-model="tableData.top"
          selectionMode="view"
          :headers="tableCryoShipperDetails"
          :isRelocationView="true"
          hasScroll
          :minHeight="null"
          tableWrapperClasses="rounded-b-md overflow-hidden"
          @onButtonClicked="onViewClicked"
        />
      </div>
    </div>

    <div v-if="hasTickets.bottom">
      <div
        class="pt-8 pb-3 px-4 text-sm border-b border-tmrw-blue border-dotted text-tmrw-blue font-semibold"
        :class="[{ 'py-3': !hasTickets.top }]"
      >
        <span class="inline-block w-[250px] mr-64">{{ headerInfo.bottom.title }}</span>
        <span>{{ headerInfo.bottom.scroll }}</span>
      </div>
      <div class="overflow-x-auto table-width">
        <!-- @vue-ignore -->
        <ScrollTableComponent
          v-model="tableData.bottom"
          selectionMode="view"
          :headers="tableCryoShipperDetails"
          :isRelocationView="true"
          hasScroll
          :minHeight="null"
          tableWrapperClasses="rounded-b-md overflow-hidden"
          @onButtonClicked="onViewClicked"
        />
      </div>
    </div>

    <div v-if="hasTickets.small">
      <div
        class="pb-3 py-3 px-4 text-sm border-b border-tmrw-blue border-dotted text-tmrw-blue font-semibold"
      >
        <span class="inline-block w-[250px] mr-64">{{ headerInfo.small.title }}</span>
        <span>{{ headerInfo.small.scroll }}</span>
      </div>

      <div class="overflow-x-auto table-width">
        <!-- @vue-ignore -->
        <ScrollTableComponent
          v-model="tableData.small"
          selectionMode="view"
          :headers="tableCryoShipperDetails"
          :isRelocationView="true"
          hasScroll
          :minHeight="null"
          tableWrapperClasses="rounded-b-md overflow-hidden"
          @onButtonClicked="onViewClicked"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import { tableCryoShipperDetails } from '@/constants/table-headers/relocation-headers'
import { computed, onMounted, ref, watch } from 'vue'
import useActions from '@/composables/useActions'
import { useRouter } from 'vue-router'
import { GRID_BOTTOM, GRID_SMALL, GRID_TOP } from '@/constants'

const router = useRouter()

const { pushViewTicket, pushSelectedTickets, pushSelectedViewMode } =
  useActions('selectedTicketsModule')

const tableData = ref<any>({
  top: {
    sort: {
      orderBy: 'procedureTime',
      direction: 'desc'
    },
    options: []
  },
  bottom: {
    sort: {
      orderBy: 'procedureTime',
      direction: 'desc'
    },
    options: []
  },
  small: {
    sort: {
      orderBy: 'procedureTime',
      direction: 'desc'
    },
    options: []
  }
})

const hasTickets = computed(() => {
  return {
    top: !!tableData.value.top.options.length,
    bottom: !!tableData.value.bottom.options.length,
    small: !!tableData.value.small.options.length
  }
})

const headerInfo = ref<any>({
  top: {},
  bottom: {}
})

const props = defineProps(['shipmentDetails'])

const setTableData = (shipment) => {
  const topGrid = shipment.cryoshipperGrids.find(
    ({ cryoshipperGridType }) => cryoshipperGridType === GRID_TOP
  )
  const bottomGrid = shipment.cryoshipperGrids.find(
    ({ cryoshipperGridType }) => cryoshipperGridType === GRID_BOTTOM
  )
  const smallGrid = shipment.cryoshipperGrids.find(
    ({ cryoshipperGridType }) => cryoshipperGridType === GRID_SMALL
  )
  const topTickets = topGrid?.tickets || []
  const bottomTickets = bottomGrid?.tickets || []
  const smallTickets = smallGrid?.tickets || []

  tableData.value = {
    top: {
      ...tableData.value.top,
      options: topTickets
    },
    bottom: {
      ...tableData.value.bottom,
      options: bottomTickets
    },
    small: {
      ...tableData.value.small,
      options: smallTickets
    }
  }
  headerInfo.value = {
    top: {
      title: 'CryoGrid 1 of 2 (Top)',
      detail: `CryoGrid #${topGrid?.cryoshipperGridId} assigned to CryoShipper ID ${
        shipment.cryoshipperLabel || '---'
      }`,
      scroll: 'Scroll Down for the Second Grid'
    },
    bottom: {
      title: `CryoGrid ${topTickets.length ? '2' : '1'} of ${
        topTickets.length ? '2' : '1'
      } (Bottom)`,
      detail: `CryoGrid #${bottomGrid?.cryoshipperGridId} assigned to CryoShipper ID ${
        shipment.cryoshipperLabel || '---'
      }`,
      scroll: topTickets.length ? 'Scroll Back Up for the First Grid' : ''
    },
    small: {
      title: 'CryoGrid 1 of 1 (Small Format)',
      detail: `CryoGrid #${smallGrid?.cryoshipperGridId} assigned to CryoShipper ID ${
        shipment.cryoshipperLabel || '---'
      }`,
      scroll: ''
    }
  }
}

const onViewClicked = (ticket) => {
  const path = '/stack-selected-tickets'
  const ticketData = {
    ticketType: 'general',
    ticketId: ticket.value.ticketId
  }
  pushViewTicket(ticketData)
  pushSelectedViewMode('normal')
  pushSelectedTickets([ticket.value.ticketId])
  router.push({ path })
}

onMounted(() => {
  setTableData(props.shipmentDetails)
})

watch(
  () => props.shipmentDetails,
  (newShipmentDetails) => {
    setTableData(newShipmentDetails)
  }
)
</script>

<style scoped>
.custom-width {
  width: calc(93vw);
}
.table-width {
  width: calc(92vw);
}
</style>
